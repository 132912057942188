.santa-div{
    display: flex;
    position: relative;
}

.santa-div img{
    position: absolute;
    width: 3rem;
    top: 48%;
    left: 41%;
    translate: -50% -50%;
}

@media (max-width: 768px){
    .santa-div img{
        position: absolute;
        width: 8vw;
        top: 15%;
        left: 99%;
        translate: -50% -50%;
        transform: rotate(2deg);
    }
}

.vip-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100vw;
}

.vip-section1-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    z-index: -100;
}

.vip-section1-header1 {
    color: #fff;
    font-size: 3rem;
    margin-top: 5vh;
    margin-bottom: 2vh;
    max-width: 70vw;
}

.vip-section1-header2 {
    color: #fff;
    font-size: 2.5rem;
}

.vip-pricing {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    gap: 75px;
    margin-bottom: 3vh;
}

.vip-section1-subheader {
    color: #fff;
    margin-bottom: 0;
    max-width: 60vw;
    margin-bottom: 5vh;
}

.vip-section1-hr-line {
    margin-block: 4vh;
    width: 45vw;
    height: 0.7vh;
    background-color: #fff;
}

.vip-section1-discount {
    color: #fff;
    font-size: 3rem;
}

.vip-section1-discount span {
    font-weight: bold;
}

.timer {
    margin-block: 0;
}

.vip-section1-card {
    background-color: #fff;
}

.vip-section1-vipcard{
    margin-top: 3vh;
}


.vip-text {
    text-align: left;
}

.vip-images {
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.vip-images img {
    height: 15rem;
    border-radius: 1rem;
    border: transparent;
    box-shadow: rgba(255, 255, 255, 0.8) 0 0 10px 4px;
}

.vip-section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 5vh;
}

.vip-section2 h1 {
    margin-bottom: 2vh;
    font-size: 3rem;
}

.vip-section2 h2 {
    margin-bottom: 5vh;
    font-size: 2rem;
}

.vip-section2-vids {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 100px;
    margin-bottom: 5vh;
}

.vip-section2 video {
    border-radius: 18px;
    height: 40vh;
}

.vip-image-middle {
    margin-bottom: 10vh;
}

@media (max-width: 768px) {
    .vip-section1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-block: 5vh;
        width: 100vw;
    }

    .vip-section1 h1 {
        color: #fff;
        font-size: 8vw;
        margin-bottom: 2vh;
        margin-top: 0;
    }

    .vip-section1-subheader {
        color: #fff;
        margin-bottom: 0;
        font-size: 5vw;
    }

    .vip-pricing {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 5vh;
    }

    .vip-price {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .vip-card-text {
        color: #fff;
        font-size: 4vw;
        text-decoration-color: #ed1566;
        text-decoration-thickness: 3px;
    }

    .vip-card-text-last {
        color: #fff;
        font-size: 4vw;
        text-decoration-color: #ed1566;
        text-decoration-thickness: 3px;
        margin-bottom: 3vh;
    }

    .vip-top {
        color: #fff;
        padding-inline: 4vw;
        padding-block: 1vw;
        background-color: #ed1566;
        border-left: 2px solid #fff;
        border-top: 2px solid #fff;
        border-right: 2px solid #fff;
        border-radius: 8px 8px 0 0;
    }

    .vip-price {
        padding-inline: 40px;
        padding-block: 25px;
        background-color: transparent;
        border: 2px solid #fff;
        border-radius: 0 0 8px 8px;
    }

    .vip-section1-vipcard {
        margin-block: 5vh;
    }

    .vip-images {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5vw;
    }

    .vip-images img {
        height: auto;
        width: 25vw;
        border-radius: 8px;
        border: transparent;
        box-shadow: rgba(255, 255, 255, 0.8) 0 0 5px 2px;
    }

    .vip-image-middle {
        margin-bottom: 5vh;
    }

    .vip-section2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block: 5vh;
    }

    .vip-section2 h1 {
        margin-bottom: 2vh;
        font-size: 7vw;
    }

    .vip-section2 h2 {
        margin-bottom: 5vh;
        font-size: 4vw;
    }

    .vip-section2-vids {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10vw;
    }

    .vip-section2 video {
        border-radius: 16px;
        height: auto;
        width: 40vw;
    }
}