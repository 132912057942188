.indicator-section1-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    z-index: -100;
}

.indicator-section1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 5vh;
}

.indicator-section1 h1{
    margin-top: 5vh;
    font-size: 3rem;
    color: #fff;
}

.indicator-section1 h2{
    margin-top: 3vh;
    font-size: 2rem;
    color: #fff;
}

.indicator-section1 h3{
    margin-top: 1.5vh;
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 3vh;
}

.indicator-section1 span{
    font-weight: bold;
    color: #c80170;
}

.indicator-section1 img{
    margin-top: 3vh;
    border-radius: 1rem;
    border: 2px solid #fff;
    box-shadow: 0 0 25px #fff;
    width: 600px;
}

.indicator-section2{
    padding-bottom: 5vh;
}

.indicator-section2-subsection{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.indicator-section2-subsection h1{
    max-width: 70%;
    font-size: 3rem;
    margin-top: 5vh;
}

.indicator-section2-subsection h2{
    max-width: 50%;
    font-size: 2rem;
    margin-top: 1.5vh;
    margin-bottom: 3vh;
}

.indicator-section2-subsection p{
    font-size: 1.5rem;
}

.indicator-images{
    width: 700px;
    height: 300px;
    position: relative;
}

.ind-img{
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 1rem;
    border: 2px solid #fff;
    box-shadow: 0 0 25px #1068ff;
    width: 600px;
}

.ind-pnl{
    position: absolute;
    top: 50%;
    right: -50px;

    border-radius: 1rem;
    border: 2px solid #fff;
    box-shadow: 0 0 25px #1068ff;
    width: 200px;
}

.indicator-section2-bottom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
}

.indicator-section2-bottom h1{
    margin-bottom: 3vh;
}

.indicator-images-bottom{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
    margin-bottom: 2vh;
}

.indicator-images-bottom2{
    margin-bottom: 4vh;
}

.indicator-images-bottom img{
    border-radius: 1rem;
    border: 2px solid #fff;
    box-shadow: 0 0 25px #1068ff;
    width: 200px;
}

@media (max-width: 768px){
    .indicator-section1-bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        object-fit: cover;
        z-index: -100;
    }
    
    .indicator-section1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block: 5vh;
    }
    
    .indicator-section1 h1{
        font-size: 7vw;
        color: #fff;
    }
    
    .indicator-section1 h2{
        margin-top: 3vh;
        font-size: 4vw;
        color: #fff;
    }
    
    .indicator-section1 h3{
        margin-top: 1.5vh;
        font-size: 3vw;
        color: #fff;
    }
    
    .indicator-section1 span{
        font-weight: bold;
        color: #c80170;
    }
    
    .indicator-section1 img{
        border-radius: 8px;
        border: 1px solid #fff;
        margin-top: 5vh;
        box-shadow: 0 0 25px #fff;
        width: 70vw;
    }
    
    .indicator-section2-subsection{
        margin-top: 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
    }
    
    .indicator-section2-subsection h1{
        max-width: 70%;
        font-size: 7vw;
    }
    
    .indicator-section2-subsection h2{
        max-width: 70%;
        font-size: 4vw;
        margin-top: 1.5vh;
        margin-bottom: 3vh;
    }
    
    .indicator-section2-subsection p{
        font-size: 3vw;
    }
    
    .indicator-images{
        width: 90vw;
        height: 50vw;
        position: relative;
    }
    
    .ind-img{
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        border-radius: 8px;
        border: 1.5px solid #fff;
        box-shadow: 0 0 25px #1068ff;
        width: 80vw;
    }
    
    .ind-pnl{
        position: absolute;
        top: 80%;
        right: 0%;
        translate: 0% -50%;
    
        border-radius: 8px;
        border: 1.5px solid #fff;
        box-shadow: 0 0 25px #1068ff;
        width: 20vw;
    }
    
    .indicator-section2-bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;
    }
    
    .indicator-section2-bottom h1{
        max-width: 90%;
        font-size: 7vw;
    }
    
    .indicator-images-bottom{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5vw;
        margin-bottom: 2vh;
    }
    
    .indicator-images-bottom2{
        margin-bottom: 4vh;
    }
    
    .indicator-images-bottom img{
        border-radius: 8px;
        border: 1.5px solid #fff;
        box-shadow: 0 0 25px #1068ff;
        width: 25vw;
    }
}