@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

@keyframes spin {
    from {
        --angle: 0deg;
    }

    to {
        --angle: 360deg;
    }
}

.chat-bubble-container{
    position: fixed;
    bottom: 2vw;
    right: 2vw;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.chat-icon{
    background-color: #ee246b;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5vw;
    padding: 0.7vw;
    border-radius: 30px;
}

.chat-icon-text{
    font-weight: bold;
}

.chat-box{
    width: 25vw;
    height: 60vh;
    background-color: #fff;
    border-radius: 1vw;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    z-index: 9999;
}

.chat-header{
    background-color: #ee246b;
    color: #fff;
    padding-block: 1vw;
    padding-inline: 1vw;
    font-size: 1.5vw;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-header button{
    background: transparent;
    border: none;
    color: #fff;
    cursor: pointer;
}

.chat-messages{
    flex-grow: 1;
    padding: 15px;
    overflow-y: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.chat-message{
    margin-bottom: 12px;
    padding: 10px;
    border-radius: 8px;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    font-size: 1.3vw;
    text-align: left;
    word-wrap: break-word;
}

.user-message{
    background-color: #007bff;
    align-self: flex-end;
    width: auto;
    max-width: 60%;
    color: #fff;
}

.support-message{
    background-color: #686868;
    align-self: flex-start;
    color: #fff;
    width: auto;
    max-width: 60%;
}

.chat-input{
    display: flex;
    border-top: 2px solid #ddd;
    padding: 0.6vw;
}

.chat-input-container{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-grow: 1;
    gap: 1vw;
}

.chat-input-container input{
    flex-grow: 1;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 0.7vw;
    font-size: 1.3vw;
}

.chat-input-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ee246b;
    color: #fff;
    border-radius: 0.7vw;
    padding: 1vw;
}

.send-icon{
    transform: rotate(-45deg);
}

.chat-form{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chat-form-container{
    background-color: #7b7b7b;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1vh;
    padding: 10px;
    border-radius: 18px;
    margin-block: 4vh;
}

.chat-form p{
    margin-bottom: 1vh;
    font-weight: bold;
    color: #fff;
}

.chat-form input{
    padding: 8px;
    border-radius: 8px;
    border: transparent;
}

.chat-form button{
    padding-inline: 30px;
    padding-block: 6px;
    font-weight: bold;
    border-radius: 8px;
    border: transparent;
    background-color: #ee246b;
    color: #fff;
}

@media (max-width: 768px) {
    .chat-bubble-container{
        position: fixed;
        bottom: 5vw;
        right: 5vw;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .chat-icon{
        background-color: #ee246b;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2vw;
        border-radius: 15px;
    }

    .chat-box{
        width: 90vw;
        height: 50vh;
        background-color: #fff;
        border-radius: 2vw;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        z-index: 9999;
    }

    /*.chat-box::after,
    .chat-box::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        border-radius: 2.5vw;
        padding: 0.5vw;
        background-image: conic-gradient(from var(--angle), transparent 20%, #fff);
        z-index: -1;
        animation: 3s spin linear infinite;
    }

    .chat-box::before {
        filter: blur(0.5rem);
        opacity: 1;
    }*/

    .chat-header{
        background-color: #ee246b;
        color: #fff;
        padding-block: 2vw;
        padding-inline: 2vw;
        font-size: 5vw;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .chat-header button{
        background: transparent;
        border: none;
        color: #fff;
        cursor: pointer;
    }

    .chat-messages{
        flex-grow: 1;
        padding: 15px;
        overflow-y: auto;
        background-color: #fff;
        display: flex;
        flex-direction: column;
    }

    .chat-message{
        margin-bottom: 12px;
        padding: 10px;
        border-radius: 8px;
        max-width: 70%;
        display: flex;
        flex-direction: column;
        font-size: 4vw;
    }

    .user-message{
        background-color: #007bff;
        align-self: flex-end;
        width: auto;
        max-width: 60%;
        color: #fff;
        text-align: left;
    }

    .support-message{
        background-color: #686868;
        align-self: flex-start;
        color: #fff;
    }

    .chat-input{
        display: flex;
        border: 2px solid #ddd;
        padding: 10px;
    }

    .chat-input-container{
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        flex-grow: 1;
        gap: 2vw;
    }

    .chat-input-container input{
        flex-grow: 1;
        padding: 12px;
        border: 1px solid #ddd;
        border-radius: 8px;
        font-size: 4vw;
    }

    .chat-input-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ee246b;
        color: #fff;
        border-radius: 25%;
        padding: 10px;
    }

    .send-icon{
        transform: rotate(-45deg);
    }

    .chat-form{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .chat-form-container{
        background-color: #7b7b7b;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1vh;
        padding: 4vw;
        border-radius: 18px;
        margin-block: 4vh;
    }
    
    .chat-form p{
        margin-bottom: 1vh;
        font-size: 4vw;
        font-weight: bold;
        color: #fff;
    }
    
    .chat-form input{
        padding: 12px;
        border-radius: 8px;
        border: transparent;
        width: 90%;
    }
    
    .chat-form button{
        padding-inline: 60px;
        padding-block: 12px;
        font-weight: bold;
        border-radius: 8px;
        border: transparent;
        background-color: #ee246b;
        color: #fff;
    }
}