.admin-dashboard {
    font-family: Arial, sans-serif;
    background-color: #3d3d3d;
    height: 100vh;
    max-width: 100vw;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.admin-dashboard h1 {
    text-align: center;
    margin-block: 20px;
    color: #fff;
}

.dashboard-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    width: 100%;
    flex: 1 1;
}

.user-list {
    border: 0.2vw solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    margin-left: 20px;
    height: 83.5vh;
    max-width: 30%;
}

.user-list-header {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.chat-section {
    border: 0.2vw solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    padding: 10px;
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-right: 20px;
    flex: 1;
    height: 83.5vh;
    overflow: hidden;
}

.chat-messages-header {
    text-align: center;
}

.chat-user-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.last-chatted-users {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: fit-content;
    overflow-y: auto;
    padding: 10px;
    border: 0.2vw solid #ddd;
    border-radius: 10px;
    height: 75vh;
    max-height: 75vh;
}

.user-item {
    border: 0.2vw solid #ddd;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
}

.user-item p {
    text-align: left;
}

.user-item.selected {
    background-color: rgb(180, 180, 180);
}

.message-box-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    gap: 8px;
    padding: 10px;
    border: 0.2vw solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    height: 75vh;
    max-height: 75vh;
}

.messages-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow-y: auto;
    flex-grow: 1;
    padding-right: 10px;
}

.message-container.user {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.message-container.admin {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.unseen {
    font-weight: bold;
    background-color: #f9f9f9;
    border: 0.2vw solid #167ac6;
}

.message.user {
    background-color: rgb(211, 211, 211);
    width: fit-content;
    padding: 7px;
    max-width: 60%;
    border-radius: 7px;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message.admin {
    background-color: #1890ff;
    color: #fff;
    width: fit-content;
    padding: 7px;
    max-width: 60%;
    border-radius: 5px;
    text-align: left;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.response-section {
    display: flex;
}

.response-section input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.response-section button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #1890ff;
    color: #fff;
    cursor: pointer;
    margin-left: 8px;
}

.response-section button:hover {
    background-color: #167ac6;
}

/* Login Screen */
.login-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.login-container {
    width: auto;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    text-align: center;
    background: #f9f9f9;
}

.login-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #3d3d3d;
}

.login-container input {
    width: 90%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.login-container button {
    width: 40%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.login-container button:hover {
    background-color: #45a049;
}

.error {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

.admin-dashboard-mobile {
    font-family: Arial, sans-serif;
    background-color: #3d3d3d;
    height: 100vh;
    /* Full viewport height */
    max-width: 100vw;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
}

.admin-dashboard-header-mobile {
    font-size: 6vw;
}

.dashboard-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    flex: 1;
    /* Takes up remaining space */
    padding-bottom: 20px;
    overflow: hidden;
    /* Prevents unintended overflow */
}

.user-list-mobile {
    border: 0.5vw solid #ddd;
    border-radius: 18px;
    background-color: #f9f9f9;
    padding: 10px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    margin-left: 0px;
    height: fit-content;
    width: 90vw;
    max-width: 90%;
    overflow-x: auto;
}

.user-list-header-mobile {
    width: 100%;
    font-size: 3vw;
    text-align: center;
    margin-bottom: 10px;
}

.user-list-users-mobile {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1vw;
    width: 100%;
}

.active-user-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.chat-section-mobile {
    border: 0.5vw solid #ddd;
    border-radius: 18px;
    background-color: #f9f9f9;
    padding: 10px;
    width: 90vw;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    /* Expands vertically */
    overflow: hidden;
    /* Prevents overflow outside the container */
    height: 80vh;
}

.last-chatted-users-mobile {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1vw;
    overflow: hidden;
    overflow-y: auto;
}

.last-chatted-users-mobile::-webkit-scrollbar {
    width: 0px;
}

.last-chatted-users-mobile::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 10px;
}

.last-chatted-header-mobile {
    font-size: 3vw;
}

.user-item-mobile {
    border: 0.4vw solid #ddd;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    text-align: left;
    font-size: 4vw;
    display: flex;
    flex-direction: column;
    gap: 0.4vw;
}

.unseen-mobile {
    font-weight: bold;
    background-color: #f9f9f9;
    border: 0.4vw solid #167ac6;
}

.chat-messages-container-mobile {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.chat-back-button {
    display: flex;
    gap: 2vw;
    padding-block: 1vw;
    background-color: #d8d8d8;
    padding-left: 2vw;
    font-weight: bold;
}

.messages-container-mobile {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    flex-grow: 1;
    background-color: #ffffff;
    padding: 2vw;
}

.messages-container-mobile::-webkit-scrollbar {
    width: 0px;
}

.messages-container-mobile::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 10px;
}

.chat-window-selected-user-mobile {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border-radius: 8px;
    border: solid 1vw #d8d8d8;
    height: 100%;
    overflow: hidden;
}

.selected-user-id-mobile {
    padding-block: 1vw;
}

.message-container-mobile.user {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.message-container-mobile.admin {
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.message-mobile.user {
    background-color: rgb(211, 211, 211);
    width: fit-content;
    padding: 7px;
    max-width: 60%;
    border-radius: 7px;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message-mobile.admin {
    background-color: #1890ff;
    color: #fff;
    width: fit-content;
    padding: 7px;
    max-width: 60%;
    border-radius: 5px;
    text-align: left;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.response-section-mobile {
    display: flex;
    padding: 1vw;
    background-color: #d8d8d8;
}

.response-section-mobile input {
    flex: 1;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    word-wrap: break-word;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.response-section-mobile button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    background-color: #1890ff;
    color: #fff;
    cursor: pointer;
    margin-left: 8px;
}