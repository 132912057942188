.terms-bg{
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-color: #0e0e0e;
    z-index: -500;
}

.terms-main{
    background-color: #0e0e0e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;
    color: #fff;
    padding-block: 5vh;
}

.terms-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 60%;
    text-align: left;
}

.terms-text h3{
    margin-top: 2vh;
    margin-bottom: 1vh;
}

.terms-text p{
    margin-top: 1vh;
    margin-bottom: 1vh;
}

.terms-list{
    margin-top: 1vh;
    margin-bottom: 2vh;
    margin-left: 5vw;
    text-align: left;
}