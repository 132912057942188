.button-dark {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 64px;
    padding-block: 16px;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    background-color: #1068ff;
    border: transparent;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s;
}

.button-dark:hover {
    transform: scale(1.1);
}

.button-dark-port {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 48px;
    padding-block: 12px;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    background-color: #1068ff;
    border: transparent;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s;
}

.button-dark-port:hover {
    transform: scale(1.1);
}

.button-light {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 64px;
    padding-block: 16px;
    font-size: 1rem;
    font-weight: bold;
    color: #1068ff;
    background-color: #fff;
    border-radius: 0.8rem;
    border: transparent;
    cursor: pointer;
    transition: transform 0.2s;
}

.button-light:hover {
    transform: scale(1.1);
    box-shadow: rgba(16, 104, 255, 0.8) 0 0 20px 5px;
    border: solid 2px #1068ff;
}

.button-vip {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-block: 16px;
    width: 8vw;
    font-size: 1rem;
    font-weight: bold;
    color: #ed1566;
    background-color: #fff;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s;
    border: transparent;
}

@media (max-width: 768px) {
    .button-light {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 9vw;
        padding-block: 2vw;
        font-size: 4vw;
        font-weight: bold;
        color: #1068ff;
        background-color: #fff;
        border-radius: 8px;
        cursor: pointer;
        border: solid 1px #1068ff;
    }

    .button-dark {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 9vw;
        padding-block: 2vw;
        font-weight: bold;
        font-size: 4vw;
        color: #fff;
        background-color: #1068ff;
        border: transparent;
        border-radius: 8px;
        cursor: pointer;
        transition: transform 0.2s;
    }

    .button-dark-port {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 9vw;
        padding-block: 2vw;
        font-weight: bold;
        font-size: 4vw;
        color: #fff;
        background-color: #1068ff;
        border: transparent;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: transform 0.2s;
    }

    .button-vip {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30vw;
        padding-block: 2vw;
        font-size: 4vw;
        font-weight: bold;
        color: #ed1566;
        background-color: #fff;
        border-radius: 8px;
        /*border: #ed1566 solid 1px;
        box-shadow: 0 0 15px 1px #ed1566;*/
        cursor: pointer;
        transition: transform 0.2s;
    }
}