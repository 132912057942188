.payment-main {
  width: 100vw;
}

.payment-section1-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  object-fit: cover;
  z-index: -100;
}

.payment-section1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.payment-section1 h1 {
  font-size: 3rem;
  color: #fff;
}

.payment-title {
  margin-top: 5vh;
}

.membership-price {
  font-size: 2rem;
}

.payment-price {
  font-size: 3rem;
}

.payment-details-glass {
  position: relative;
  padding: 35px;
  border-radius: 1.5rem;
  background-color: #0e0e0e;
  box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.payment-details-glass::after,
.payment-details-glass::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  border-radius: 1.7rem;
  padding: 0.2rem;
  background-image: conic-gradient(from var(--angle), transparent 20%, #1068ff);
  z-index: -1;
  animation: 3s spin linear infinite;
}

.payment-details-glass::before {
  filter: blur(1.5rem);
  opacity: 1;
}

.payment-details-glass h1 {
  margin-bottom: 2vh;
}

.payment-qr {
  border-radius: 10px;
  width: 300px;
  box-shadow: 0px 0px 20px #fff;
}

.payment-details-glass p {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 2vh;
  color: #fff;
}

.payment-copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10rem;
  height: 3rem;
  margin-top: 1.5rem;
  padding-inline: 2rem;
  padding-block: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
  background-color: transparent;
  border: solid 2px #fff;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.payment-copy-button:hover {
  transform: scale(1.1);
  box-shadow: rgba(255, 255, 255, 0.8) 0 0 10px 4px;
}

.payment-crypto-btn {
  margin-top: 3vh;
  width: 8rem;
  height: 2.5rem;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
  background-color: transparent;
  border: solid 2px #fff;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.payment-crypto-btn:hover {
  transform: scale(1.1);
  box-shadow: rgba(255, 255, 255, 0.8) 0 0 10px 4px;
}

.payment-copy-icon {
  width: 15px;
  margin-right: 1rem;
}

.payment-divider {
  height: 3vh;
  border: 1px solid #7c7c7c;
}

.payment-subheader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 2vh;
}

.payment-subheader h2 {
  color: #7c7c7c;
  font-weight: 400;
}

/* New CSS for Toggle Buttons and Labels */
.payment-options {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.toggle-group {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-group label {
  margin-top: 8px;
  font-size: 1.2rem;
  color: #fff;
}

.toggle-btn {
  margin-top: 3vh;
  background-color: #c80170;
  border: 2px solid #fff;
  border-radius: 99px;
  width: 80px;
  height: 40px;
  transition:
    background-color 0.1s ease,
    border-color 0.2s ease;
  cursor: pointer;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.748);
  position: relative;
}

.toggle-btn .thumb {
  height: 30px;
  width: 30px;
  background-color: #fff;
  border-radius: 99px;
  transform: translateX(0);
  transition: left 0.15s ease;
  position: absolute;
  left: 3px;
  top: 50%;
  transform: translateY(-50%);
}

.toggle-btn.toggled {
  background-color: #1068ff;
}

.toggle-btn:hover {
  border-color: blanchedalmond;
}

.toggle-btn.toggled .thumb {
  left: calc(80px - 37px);
}

/*SECTION 2*/

.payment-section2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-block: 5vh;
}

.payment-section2 h1 {
  font-size: 3rem;
  margin-top: 5vh;
}

.payment-section2 h2 {
  font-size: 1.5rem;
  margin-top: 3vh;
  margin-bottom: 3vh;
}

@media (max-width: 768px) {
  .payment-main {
    width: 100vw;
  }

  .payment-section1-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    z-index: -100;
  }

  .payment-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .payment-section1 h1 {
    font-size: 7vw;
    color: #fff;
  }

  .payment-title {
    margin-top: 5vh;
  }

  .payment-price {
    font-size: 7vw;
  }

  .membership-price {
    font-size: 6vw;
  }

  .payment-details-glass {
    position: relative;
    padding: 5vw;
    border-radius: 1.5rem;
    background-color: #0e0e0e;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
    margin-bottom: 10vh;
    max-width: 80vw;
    width: 80vw;
  }

  .payment-details-glass::after,
  .payment-details-glass::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 1.7rem;
    padding: 0.2rem;
    background-image: conic-gradient(
      from var(--angle),
      transparent 20%,
      #1068ff
    );
    z-index: -1;
    animation: 3s spin linear infinite;
  }

  .payment-details-glass::before {
    filter: blur(1.5rem);
    opacity: 1;
  }

  .payment-details-glass h1 {
    margin-bottom: 2vh;
  }

  .payment-qr {
    border-radius: 5px;
    width: 40vw;
    box-shadow: 0px 0px 20px #fff;
  }

  .payment-details-glass p {
    font-size: 4vw;
    font-weight: bold;
    margin-top: 2vh;
    color: #fff;
    text-align: center;
  }

  .payment-copy-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    margin-top: 3vh;
    margin-bottom: 1.5vh;
    padding-inline: 8vw;
    padding-block: 2.5vw;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    background-color: transparent;
    border: solid 2px #fff;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s;
  }

  .payment-copy-button:hover {
    transform: scale(1.1);
    box-shadow: rgba(255, 255, 255, 0.8) 0 0 10px 4px;
  }

  .payment-crypto-btn {
    margin-top: 3vh;
    width: 15vw;
    height: 3vh;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    background-color: transparent;
    border: solid 2px #fff;
    border-radius: 0.2rem;
    cursor: pointer;
    transition: transform 0.2s;
  }

  .payment-copy-icon {
    width: 15px;
    margin-right: 1rem;
  }

  .payment-divider {
    height: 4vh;
    border: 1px solid #7c7c7c;
  }

  .payment-subheader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 2vh;
  }

  .payment-subheader h2 {
    font-size: 4vw;
    color: #7c7c7c;
    font-weight: 400;
  }

  .payment-details-address {
    max-width: 70vw;
    word-wrap: break-word;
  }

  /* New CSS for Toggle Buttons and Labels */
  .payment-options {
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .toggle-group {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .toggle-group label {
    margin-top: 8px;
    font-size: 1.2rem;
    color: #fff;
  }

  .toggle-btn {
    margin-top: 3vh;
    background-color: #c80170;
    border: 2px solid #fff;
    border-radius: 99px;
    width: 80px;
    height: 40px;
    transition:
      background-color 0.1s ease,
      border-color 0.2s ease;
    cursor: pointer;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.748);
    position: relative;
  }

  .toggle-btn .thumb {
    height: 30px;
    width: 30px;
    background-color: #fff;
    border-radius: 99px;
    transform: translateX(0);
    transition: left 0.15s ease;
    position: absolute;
    left: 3px;
    top: 50%;
    transform: translateY(-50%);
  }

  .toggle-btn.toggled {
    background-color: #1068ff;
  }

  .toggle-btn:hover {
    border-color: blanchedalmond;
  }

  .toggle-btn.toggled .thumb {
    left: calc(80px - 37px);
  }

  /*SECTION 2*/

  .payment-section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 5vh;
  }

  .payment-section2 h1 {
    font-size: 7vw;
  }

  .payment-section2 h2 {
    font-size: 4vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
  }
}

.payment-crypto-selection {
  display: flex;
  gap: 2vw;
}
