@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

.services-section1-bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    z-index: -100;
}

.services-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.services-section1 h1 {
    color: #fff;
    font-size: 3rem;
    margin-top: 5vh;
}

.services-categories {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5vw;
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 100%;
    max-width: 95vw;
}

.category {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px;
    border-radius: 1.5rem;
    width: 16vw;
    background-color: #fff;
    box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
    height: 200px;
    width: 300px;
}

.category::after,
.category::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 1.7rem;
    padding: 0.2rem;
    background-image: conic-gradient(from var(--angle), transparent 20%, #0147ff);
    z-index: -1;
    animation: 3s spin linear infinite;
}

.category::before {
    filter: blur(1.5rem);
    opacity: 1;
}

@keyframes spin {
    from {
        --angle: 0deg;
    }

    to {
        --angle: 360deg;
    }
}

.category h1 {
    color: #000;
    margin-top: 0vh;
    font-size: 1.5rem;
}

.category p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #000;
}

/*----------------
-----SECTION2-----
------------------*/

.services-section2 {
    padding-top: 5vh;
    padding-bottom: 5vh;
}

.services-section2 h1 {
    font-size: 3rem;
}

.cat h2 {
    font-size: 2rem;
    margin-top: 10vh;
    margin-bottom: 2vh;
}

.cat p {
    font-size: 1.2rem;
}

.cat span {
    color: #1068ff;
    font-weight: bold;
}

.cat button {
    width: 12rem;
    height: 3rem;
    margin-top: 1.5rem;
    padding-inline: 2rem;
    padding-block: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    background-color: #0147ff;
    border: transparent;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s;
}

.cat button:hover {
    transform: scale(1.1);
    box-shadow: rgba(255, 255, 255, 0.8) 0 0 10px 4px;
}

.p2 {
    margin-top: 1vh;
}

.cat-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-inline: 150px;
}

.cat-content img {
    border-radius: 1rem;
    height: 250px;
    min-height: 150px;
    border: solid 2px #fff;
    box-shadow: rgba(16, 104, 255, 0.8) 0px 0px 15px 5px;
}

.cat-content1 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: 50%;
    padding-right: 30px;
}

.cat-content2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50%;
    padding-left: 30px;
}

.cat-content2-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30vw;
}

.cat-copytading-images {
    margin-top: 3vh;
    height: 300px;
    width: 600px;
    position: relative;
}

.image-one {
    position: absolute;
    top: 60%;
    left: 0%;
    translate: 0% -50%;
}

.image-two {
    position: absolute;
    top: 30%;
    left: 35%;
    translate: -0% -50%;
}

.mobile {
    display: none;
}

@media (max-width:768px) {
    .services-section1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }

    .services-section1 h1 {
        color: #fff;
        font-size: 7vw;
        margin-top: 5vh;
    }

    .services-categories {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5vw;
        margin-top: 5vh;
        margin-bottom: 5vh;
        width: 100%;
    }

    .category {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 20px;
        border-radius: 1.5rem;
        width: 15rem;
        background-color: #fff;
        box-shadow: 15px 15px 15px rgba(0, 0, 0, 0.2);
    }

    .category::after,
    .category::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        border-radius: 1.7rem;
        padding: 0.2rem;
        background-image: conic-gradient(from var(--angle), transparent 20%, #0147ff);
        z-index: -1;
        animation: 3s spin linear infinite;
    }

    .category::before {
        filter: blur(1.5rem);
        opacity: 1;
    }

    @keyframes spin {
        from {
            --angle: 0deg;
        }

        to {
            --angle: 360deg;
        }
    }

    .category h1 {
        color: #000;
        margin-top: 0vh;
        font-size: 7vw;
    }

    .category p {
        margin-top: 1vh;
        font-size: 4vw;
        font-weight: bold;
        color: #000;
    }

    .category button {
        width: 12rem;
        height: 3rem;
        margin-top: 1.5rem;
        padding-inline: 2rem;
        padding-block: 0.5rem;
        font-weight: bold;
        font-size: 1rem;
        color: #fff;
        background-color: #0147ff;
        border: transparent;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: transform 0.2s;
    }

    .category button:hover {
        transform: scale(1.1);
        box-shadow: rgba(255, 255, 255, 0.8) 0 0 10px 4px;
    }

    /*----------------
    -----SECTION2-----
    ------------------*/

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }

    .services-section2 {
        padding-block: 5vh;
    }

    .services-section2 h1 {
        font-size: 10vw;
    }

    .cat h2 {
        font-size: 7vw;
        margin-top: 10vh;
        margin-bottom: 2vh;
    }

    .cat p {
        font-size: 4vw;
    }

    .cat span {
        color: #1068ff;
        font-weight: bold;
    }

    .p2 {
        margin-top: 1vh;
    }

    .cat-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding-inline: 0px;
    }

    .cat-content img {
        border-radius: 1rem;
        height: 40vw;
        min-height: 0;
        border: solid 2px #fff;
        box-shadow: rgba(16, 104, 255, 0.8) 0px 0px 15px 5px;
    }

    .cat-content1 {
        order: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-right: 0;
    }

    .cat-content2 {
        order: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-left: 0;
    }

    .cat-content2-text {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70vw;
    }

    .cat-copytading-images {
        margin-top: 3vh;
        height: 30vh;
        width: 70vw;
        position: relative;
    }

    .cat-copytading-images img {
        height: 18vh;
    }

    .image-one {
        position: absolute;
        top: 100%;
        left: 0%;
        translate: 0% -100%;

    }

    .image-two {
        position: absolute;
        top: 0%;
        left: 100%;
        translate: -100% 0%;
    }
}