.section1-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    z-index: -100;
}

.about-animation{
    position: absolute;
    margin-top: 1vh;
    border: 10px solid #fff;
}

.about-section1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw; 
}

.about-section1 h1{
    color: #fff;
    font-size: 3rem;
    margin-top: 5vh;
    margin-bottom: 5vh;
}

.about-section-paragraphs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vh;
}

.about-section-paragraphs p{
    text-align: center;
    width: 60%;
    font-size: 1.5rem;
    color: #fff;
}

.about-section2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 5vh;
}

.about-section2 h1{
    width: 75%;
    margin-bottom: 5vh;
    font-size: 3rem;
}

.about-section2 p{
    width: 60%;
    margin-bottom: 5vh;
    font-size: 1.5rem;
}

.about-section2 button{
    width: 12rem;
    height: 3rem;
    margin-top: 1.5rem;
    padding-inline: 2rem;
    padding-block: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    background-color: #0147ff;
    border: transparent;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s;
}

.about-section2 button:hover{
    transform: scale(1.1);
    box-shadow: rgba(255, 255, 255, 0.8) 0 0 10px 4px;
}

.about-section3{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-image: url(../../../public/assets/images/backgrounds/bg2.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-block: 5vh;
}

.about-section3 h1{
    color: #fff;
    font-size: 3rem;
    margin-bottom: 5vh;
}

.about-section3-socials{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-block: 28px;
    padding-inline: 112px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    box-shadow: 0 0 30px rgba(256, 256, 256, 0.3);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.about-section3-socials-header h2{
    font-size: 1.5rem;
    color: #fff;
}

.about-section3-socials-icons{
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
}

.about-section3-socials-icons img{
    width: 52px;
}

.about-section3 p{
    color: #fff;
    font-size: 1.5rem;
    margin-top: 5vh;
}
 
.about-section3 button{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 15rem;
    margin-top: 5vh;
    height: 3rem;
    padding-inline: 2rem;
    padding-block: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    background-color: #1068ff;
    border: transparent;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s;
}

.about-section3 button:hover{
    transform: scale(1.1);
}

.about-section3-buttin-img{
    width: 20px;
}