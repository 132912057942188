.faq-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 20vh;
    position: relative;
}

.faq-header{
    color: #fff;
    font-size: 3rem;
    margin-bottom: 5vh;
    margin-top: 5vh;
}

.faq-content {
    width: 60vw;
    text-align: left;
}

.faq-item {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 2px solid #f0f0f0;
}

.faq-question {
    text-transform: uppercase;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    display: flex;
    justify-content: space-between;
}

.faq-arrow {
    font-size: 1.2rem;
    transition: transform 0.3s ease;
}

.faq-arrow.expanded {
    transform: rotate(180deg);
}

.faq-answer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease, margin-block 0.2s ease;
    font-size: 1.2rem;
    color: #fff;
}

.faq-answer.expanded {
    max-height: 150px; /* Adjust as needed */
    margin-block: 32px;
}

@media (max-width:768px){
    .faq-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 35vh;
        position: relative;
    }
    
    .faq-header{
        color: #fff;
        font-size: 7vw;
        margin-bottom: 5vh;
        margin-top: 5vh;
    }
    
    .faq-content {
        width: 80vw;
        text-align: left;
        position: absolute;
        top: 15%;

    }
    
    .faq-item {
        margin-bottom: 1vh;
        padding-bottom: 1vh;
        border-bottom: 2px solid #f0f0f0;
    }
    
    .faq-question {
        text-transform: uppercase;
        cursor: pointer;
        font-size: 4vw;
        font-weight: bold;
        color: #fff;
        display: flex;
        justify-content: space-between;
    }
    
    .faq-arrow {
        font-size: 4vw;
        transition: transform 0.3s ease;
    }
    
    .faq-arrow.expanded {
        transform: rotate(180deg);
    }
    
    .faq-answer {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease, margin-block 0.2s ease;
        font-size: 4vw;
        color: #fff;
    }
    
    .faq-answer.expanded {
        max-height: 30vh; /* Adjust as needed */
        margin-block: 3vh;
    }
}
