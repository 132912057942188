.tradersnotes-section1{
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.tradersnotes-section1-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vh;
}

.tradersnotes-section1-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

.tradersnotes-section1-header img{
    height: 35vh;
    width: auto;
}

.tradersnotes-section1-header span{
    color: #c80170;
    font-size: 3vw;
}

.tradersnotes-section1-header h1{
    color: #fff;
    font-size: 3vw;
}

.tradersnotes-section1-header h2{
    color: #fff;
    font-size: 2.5vw;
}

.tradersnotes-section1-hrline{
    width: 30vw;
    border: solid 4px #fff;
    margin-block: 2vh;
}

@media (max-width: 768px) {
    .tradersnotes-section1{
        padding-top: 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .tradersnotes-section1-header{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 3vh;
    }
    
    .tradersnotes-section1-buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 10vw;
    }
    
    .tradersnotes-section1-header img{
        height: auto;
        width: 80vw;
    }
    
    .tradersnotes-section1-header span{
        color: #c80170;
        font-size: 7vw;
    }
    
    .tradersnotes-section1-header h1{
        color: #fff;
        font-size: 7vw;
    }
    
    .tradersnotes-section1-header h2{
        color: #fff;
        font-size: 4vw;
        margin-top: 2vh;
    }
    
    .tradersnotes-section1-hrline{
        width: 70vw;
        border: solid 3px #fff;
        margin-top: 6vh;
    }
}