.main-content{
    overflow-x: hidden;
}

.section1-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    z-index: -100;
}

.section1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    padding-block: 5vh;
}

.section1 h1{
    font-size: 2vw;
    color: #fff;
}

.section1 h2{
    font-size: 1.2vw;
    color: #fff;
    margin-top: 1vh;
}

.section1 img{
    width: 10vw;
    border-radius: 0.8vw;
    border: transparent;
    box-shadow: rgba(255, 255, 255, 0.8) 0 0 10px 4px;
}

.section1-images{
    margin-top: 5vh;
    margin-bottom: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2vw;
}

/*----------
------------
SECTION 2*/

.section2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-block: 5vh;
}

.section2 h1{
    width: 70vw;
    font-size: 2vw;
}

.section2 p{
    font-size: 1vw;
    width: 60vw;
    margin-top: 1vh;
    margin-bottom: 5vh;
}

.section2-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 2vw;
}

.home-text-desktop{
    color: #000;
}

.home-text-mobile{
    color: #000;
}

/*----------
------------
SECTION 3*/

.section3{
    background-image: url(../../../public/assets/images/backgrounds/bg2.png);;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-block: 5vh;
}

.section3 h1{
    color: #fff;
    font-size: 2vw;
}

.section3 h2{
    color: #fff;
    margin-top: 1vh;
    font-size: 1.2vw;
}

.section3-automated-trading{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
    gap: 2vw;
}

.section3-automated-trading-text{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 1vh;
    width: 50%;
}

.section3-automated-trading-text p{
    color: #fff;
    font-size: 1vw;
    width: 50%;
    max-width: 25vw;
}

.section3-automated-trading-images{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;
    width: 50%;
}

.section3-images-top{
    width: 100%;
    max-width: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section3-images-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 20vw;
}

.section3-team{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5vh;
}

.section3-team h1{
    width: 50%;
    max-width: 70vw;
}

.section3-team-leftnright{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 52px;
    margin-top: 5vh;
    width: 100%;
}

.section3-team-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 16px;
    width: 50%;
}

.section3-team-left p{
    font-size: 1vw;
    color: #fff;
    width: 50%;
    max-width: 25vw;
}

.section3-team-right{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
}

.section3-cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.section3-card{
    width: 50%;
    max-width: 60vw;
    padding-block: 56px;
    padding-inline: 112px;
    border-radius: 2rem;
    margin-top: 88px;


    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 0 30px rgba(256, 256, 256, 0.1);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(4px);
    border: 2px solid rgba(255, 255, 255, 0.3);
}

.section3-card h1{
    font-size: 2vw;
    color: #fff;
}

.section3-card p{
    margin-top: 64px;
    font-size: 1vw;
    color: #fff;
}

.card1{
    position: relative;
    margin-left: 10vw;
}

.card2{
    margin-top: 10vh;
    margin-bottom: 5vh;
    position: relative;
    margin-right: 10vw;
}

.card1-image{
    position: absolute;
    top: 50%;
    left: -10%;
    translate: -50% -50%;
    width: 20vw;
}

.card2-image{
    position: absolute;
    top: 50%;
    left: 110%;
    translate: -50% -50%;
    width: 20vw;
}

.home-text-mobile{
    display: none;
}










@media (max-width: 768px) {
    .section1-bg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        object-fit: cover;
        z-index: -100;
    }
    
    .section1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        padding-block: 5vh;
    }
    
    .section1 h1{
        font-size: 7vw;
        color: #fff;
    }
    
    .section1 h2{
        font-size: 4vw;
        color: #fff;
        margin-top: 1vh;
    }
    
    .section1 img{
        width: 25vw;
        border-radius: 8px;
        border: transparent;
        box-shadow: rgba(255, 255, 255, 0.8) 0 0 5px 2px;
    }
    
    .section1-images{
        margin-top: 5vh;
        margin-bottom: 5vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 5vw;
    }
    
    /*----------
    ------------
    SECTION 2*/
    
    .section2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding-block: 5vh;
    }
    
    .section2 h1{
        width: 90vw;
        font-size: 7vw;
    }

    .home-text-mobile{
        display: block;
    }
    .home-text-desktop{
        display: none;
    }
    
    .section2 h2{
        font-size: 4vw;
        width: 60vw;
        margin-top: 1vh;
        margin-bottom: 5vh;
    }
    
    .section2-buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 8vw;
    }
    
    /*----------
    ------------
    SECTION 3*/
    
    .section3{
        background-image: url(../../../public/assets/images/backgrounds/bg2.png);;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-block: 5vh;
    }
    
    .section3 h1{
        color: #fff;
        font-size: 7vw;
    }
    
    .section3 h2{
        color: #fff;
        margin-top: 1vh;
        font-size: 4vw;
    }
    
    .section3-automated-trading{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;
        gap: 2vw;
    }
    
    .section3-automated-trading-text{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        gap: 1vh;
        width: 50%;
    }
    
    .section3-automated-trading-text p{
        text-align: left;
        color: #fff;
        font-size: 3vw;
        width: 100%;
        max-width: 50vw;
    }
    
    .section3-automated-trading-images{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 2vh;
        width: 30%;
    }
    
    .section3-images-top{
        width: 100%;
        max-width: 20vw;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .section3-images-bottom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2vh;
        width: 100%;
        max-width: 20vw;
    }

    .section3-images-top img{
        max-height: 7vh;
    }

    .section3-images-bottom img{
        max-height: 7vh;
    }
    
    .section3-team{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;
    }
    
    .section3-team h1{
        width: 100%;
        max-width: 90vw;
    }
    
    .section3-team-leftnright{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 52px;
        margin-top: 5vh;
        width: 100%;
    }
    
    .section3-team-left{
        order: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        width: 100%;
    }
    
    .section3-team-left p{
        font-size: 3vw;
        color: #fff;
        width: 70%;
        max-width: 70vw;
    }
    
    .section3-team-right{
        order: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    
    .section3-cards{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .section3-card{
        width: 75%;
        max-width: 75vw;
        padding-block: 4vw;
        padding-inline: 8vw;
        border-radius: 2rem;
        margin-top: 5vh;
    
    
        background: rgba(255, 255, 255, 0.05);
        border-radius: 16px;
        box-shadow: 0 0 30px rgba(256, 256, 256, 0.1);
        backdrop-filter: blur(40px);
        -webkit-backdrop-filter: blur(4px);
        border: 2px solid rgba(255, 255, 255, 0.3);
    }
    
    .section3-card h1{
        font-size: 7vw;
        color: #fff;
    }
    
    .section3-card p{
        margin-top: 1vh;
        font-size: 3vw;
        color: #fff;
        margin-bottom: 5vh;
    }
    
    .card1{
        position: relative;
        margin-left: 0;
    }
    
    .card2{
        margin-top: 17vh;
        margin-bottom: 25vh;
        position: relative;
        margin-right: 0;
    }
    
    .card1-image{
        position: absolute;
        top: 120%;
        left: 50%;
        translate: -50% -50%;
        width: 70vw;
    }
    
    .card2-image{
        position: absolute;
        top: 142%;
        left: 50%;
        translate: -50% -50%;
        width: 70vw;
    }
}