.main-content-copytrading{
    position: relative;
}

.copy-section1-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    z-index: -100;
}

.copy-section1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copy-section1 span{
    color: #c80170;
}

.copy-section1-header h1{
    font-size: 3rem;
    color: #fff;
    margin-top: 5vh;
}

.copy-section1-header h2{
    color: #fff;
    font-size: 2rem;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.copy-section1-buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    gap: 40px;
    margin-bottom: 3vh;
}

.copy-section1-horizontal-line{
    border: 2px solid #fff;
    width: 80vw;
    max-width: 1100px;
}

.copy-section1-images-main{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.copy-section1-images-main h2{
    font-size: 1.5rem;
    margin-top: 0;
}

.copy-section1-images-left{
 display: flex;
 flex-direction: column;
 align-items: flex-end;
 justify-content: center;
 width: 50vw;
}

.copy-section1-images-right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 50vw;
}

.copy-section1-images-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copy-section1-images-wrapper h2{
    color: #fff;
    font-size: 1.5rem;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.copy-section1-images-wrapper img{
    border: 2px solid #fff;
    box-shadow: 0 0 25px #fff;
    border-radius: 1rem;
    width: 400px;
    margin-top: 3vh;
}

.copy-section1-50K-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding-inline: 4vw;
    padding-block: 1vw;
    margin-block: 5vh;
}

.copy-section1-50K-text h1{
    font-size: 3rem;
    color: #fff;
}

.copy-section1-50K-text h2{
    color: #fff;
    font-size: 2rem;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.copy-section1-50K-buttons{
    display: flex;
    gap: 2vw;
}

.copy-section2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 5vh;
}

.copy-section2 span{
    color: red;
}

.copy-section2 h1{
    font-size: 3rem;
}

.copy-section2 h2{
    font-size: 2rem;
}

.copy-section2 button{
    text-align: center;
    min-width: 240px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 28px;
    padding-block: 12px;
    color: #fff;
    background-color: #0147ff;
    border: transparent;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 1rem;
    margin-top: 2vh;
}

.copy-section2 button:hover{
    transform: scale(1.1);
}

.copy-section2-steps{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 56px;
    background-color: #0e0e0e;
    margin-block: 5vh;
    border-radius: 2rem;
    padding-block: 80px;
    padding-inline: 160px;
}

.copy-section2-steps::after, .copy-section2-steps::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 2.3rem;
    padding: 0.3rem;
    background-image: conic-gradient(from var(--angle), transparent 20%, #0147ff);
    z-index: -1;
    animation: 5s spin linear infinite;
}

.copy-section2-steps::before{
    filter: blur(1.5rem);
    opacity: 1;
}

.copy-step{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copy-step h2{
    color: #fff;
}

.copy-step h3{
    color: #fff;
}

.copy-step p{
    color: #fff;
}

.copy-step button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copy-step button:hover{
    transform: scale(1.1);
}

.copy-step-images{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.copy-step-images img{
    margin-top: 2vh;
    border-radius: 0.7rem;
    border: 2px solid #fff;
    width: 150px;
    transition: transform 0.2s;
}

.copy-step-images img:hover{
    transform: scale(1.1);
}

.copy-section3{
    padding-block: 5vh;
    background-color: #0e0e0e;
}

.copy-section3 h1{
    color: #fff;
    font-size: 3rem;
}

.copy-section3 h2{
    color: #fff;
    font-size: 2rem;
}

.copy-section3-row{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:56px;
    margin-top: 5vh;
    width: 100vw;
}

.copy-section3-row img{
    border: 2px solid #fff;
    box-shadow: 0 0 15px #fff;
    border-radius: 1rem;
    margin-top: 3vh;
    width: 400px;
}

.copy-section3-left{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;
}

.copy-section3-right{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 50%;
}

.copy-section3-right-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copy-section3-left-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.copy-section3 button{
    width: 15rem;
    margin-top: 5vh;
    height: 3rem;
    padding-inline: 2rem;
    padding-block: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    background-color: #0147ff;
    border: transparent;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s;
}

.copy-section3 button:hover{
    transform: scale(1.1);
}

.copy-section3-pnl-main{
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    gap: 28px;
}

.copy-section3-pnl-row{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 856px;
}

.copy-section3-pnl-row-images{
    width: 856px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.copy-section3-pnl-row-images img{
    border: 2px solid #fff;
    box-shadow: 0 0 15px #fff;
    border-radius: 1rem;
    width: calc(856px/3 - 28px);
}

.copy-section3 video{
    border: 2px solid #fff;
    box-shadow: 0 0 15px #fff;
    border-radius: 1rem;
    margin-top: 5vh;
    width: 428px;
}

@media (max-width: 768px) {
    .copy-section1{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block: 5vh;
    }
    
    .copy-section1 span{
        color: #c80170;
    }
    
    .copy-section1 h1{
        font-size: 7vw;
        color: #fff;
        margin-top: 0;
    }
    
    .copy-section1 h2{
        color: #fff;
        font-size: 4vw;
        margin-top: 3vh;
    }
    
    .copy-section1-buttons{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 10vw;
    }
    
    .copy-section1-horizontal-line{
        border: 2px solid #fff;
        width: 80vw;
        max-width: 1100px;
    }
    
    .copy-section1-images-main{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;
        margin-bottom: 5vh;
    }
    
    .copy-section1-images-main h2{
        font-size: 4vw;
        margin-top: 0;
    }
    
    .copy-section1-images-left{
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     width: 90vw;
    }
    
    .copy-section1-images-right{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90vw;
    }
    
    .copy-section1-images-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .copy-section1-images-wrapper img{
        border: 2px solid #fff;
        box-shadow: 0 0 15px #fff;
        border-radius: 8px;
        width: 80vw;
        margin-top: 1vh;
    }

    .copy-section1-50K-main{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding-inline: 0vw;
        padding-block: 3vh;
        margin-block: 3vh;
        width: 80vw;
    }

    .copy-section1-50K-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 80%;
    }
    
    .copy-section1-50K-text h1{
        font-size: 7vw;
        color: #fff;
    }
    
    .copy-section1-50K-text h2{
        color: #fff;
        font-size: 4vw;
        margin-top: 3vh;
        margin-bottom: 3vh;
        max-width: 80%;
    }
    
    .copy-section1-50K-buttons{
        display: flex;
        flex-direction: column;
        gap: 4vw;
    }
    
    .copy-section2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block: 5vh;
    }
    
    .copy-section2 span{
        color: red;
    }
    
    .copy-section2 h1{
        font-size: 7vw;
    }
    
    .copy-section2 h2{
        font-size: 4vw;
        max-width: 70vw;
    }
    
    .copy-section2 button{
        text-align: center;
        min-width: 200px;
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 28px;
        padding-block: 12px;
        color: #fff;
        background-color: #0147ff;
        border: transparent;
        font-size: 4vw;
        font-weight: bold;
        border-radius: 1rem;
        margin-top: 2vh;
    }
    
    .copy-section2 button:hover{
        transform: scale(1.1);
    }
    
    .copy-section2-steps{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 56px;
        background-color: #0e0e0e;
        margin-block: 5vh;
        border-radius: 2rem;
        padding-block: 5vh;
        padding-inline: 5vw;
    }
    
    .copy-section2-steps::after, .copy-section2-steps::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        border-radius: 2.3rem;
        padding: 0.3rem;
        background-image: conic-gradient(from var(--angle), transparent 20%, #0147ff);
        z-index: -1;
        animation: 5s spin linear infinite;
    }
    
    .copy-section2-steps::before{
        filter: blur(1.5rem);
        opacity: 1;
    }
    
    .copy-step{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .copy-step h2{
        color: #fff;
    }
    
    .copy-step h3{
        color: #fff;
    }
    
    .copy-step p{
        color: #fff;
    }
    
    .copy-step button{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .copy-step button:hover{
        transform: scale(1.1);
    }
    
    .copy-step-images{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }
    
    .copy-step-images img{
        margin-top: 2vh;
        border-radius: 0.7rem;
        border: 2px solid #fff;
        width: 30vw;
        transition: transform 0.2s;
    }
    
    .copy-step-images img:hover{
        transform: scale(1.1);
    }
    
    .copy-section3{
        padding-block: 5vh;
        background-color: #0e0e0e;
    }
    
    .copy-section3 h1{
        color: #fff;
        font-size: 7vw;
    }
    
    .copy-section3 h2{
        color: #fff;
        font-size: 4vw;
    }
    
    .copy-section3-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:56px;
        margin-top: 5vh;
        width: 100vw;
    }
    
    .copy-section3-row img{
        border: 1.5px solid #fff;
        box-shadow: 0 0 5px #fff;
        border-radius: 1rem;
        margin-top: 3vh;
        width: 80vw;
    }
    
    .copy-section3-left{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
    
    .copy-section3-right{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
    
    .copy-section3-right-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .copy-section3-left-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .copy-section3 button{
        width: 15rem;
        margin-top: 5vh;
        height: 3rem;
        padding-inline: 2rem;
        padding-block: 0.5rem;
        font-weight: bold;
        font-size: 1rem;
        color: #fff;
        background-color: #0147ff;
        border: transparent;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: transform 0.2s;
    }
    
    .copy-section3 button:hover{
        transform: scale(1.1);
    }
    
    .copy-section3-pnl-main{
        margin-top: 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        gap: 5vw;
    }
    
    .copy-section3-pnl-row{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    
    .copy-section3-pnl-row-images{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        gap: 5vw;
    }
    
    .copy-section3-pnl-row-images img{
        border: 1.5px solid #fff;
        box-shadow: 0 0 5px #fff;
        border-radius: 8px;
        width: 25vw;
    }
    
    .copy-section3 video{
        border: 1.5px solid #fff;
        box-shadow: 0 0 5px #fff;
        border-radius: 1rem;
        margin-top: 5vh;
        width: 80vw;
    }
}