.blofin-guide-section1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 5vh;
}

.blofin-guide-section1 h1{
    font-size: 3rem;
    color: #fff;
}

.blofin-guide-section1 h2{
    color: #fff;
    font-size: 1.5rem;
}

.blofin-trader-name{
    font-size: 4rem;
}

.blofin-guide-section1 h3{
    font-size: 1.5rem;
    color: #fff;
    width: 60%;
}

.blofin-trader-name-span{
    color: #ff8400;
}

.blofin-guide-section1 span{
    color: #ff8400;
}

.blofin-trader-name-box{
    padding-block: 16px;
    padding-inline: 32px;
    border-radius: 1.5rem;
    margin-block: 3vh;
    background-color: #000;
    border: solid 1px #ff8400;
    box-shadow: 0 0 20px #ff8400;
}

.blofin-aff-guide-image{
    width: 50vw;
}

.blofin-aff-guide-image img{
    border: solid 5px #ff8400;
    border-radius: 3rem;
    margin-top: 5vh;
    width: stretch;
}




@media (max-width: 768px) {
    .blofin-guide-section1 h1{
        font-size: 7vw;
        color: #fff;
    }
    
    .blofin-guide-section1 h2{
        color: #fff;
        font-size: 4vw;
    }
    
    .blofin-trader-name{
        font-size: 4rem;
    }
    
    .blofin-guide-section1 h3{
        font-size: 3vw;
        color: #fff;
        width: 80%;
    }
    
    .blofin-trader-name-span{
        color: #ff8400;
    }
    
    .blofin-guide-section1 span{
        color: #ff8400;
    }
    
    .blofin-trader-name-box{
        padding-block: 16px;
        padding-inline: 32px;
        border-radius: 8px;
        margin-block: 3vh;
        background-color: #000;
        border: solid 1px #ff8400;
        box-shadow: 0 0 20px #ff8400;
    }

    .blofin-aff-guide-image{
        width: 70vw;
    }
    
    .blofin-aff-guide-image img{
        border: solid 2px #ff8400;
        border-radius: 1rem;
        margin-top: 3vh;
        width: stretch;
    }
}