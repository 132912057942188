.port-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 5vh;
}

.port-section1 h1 {
    color: #fff;
    margin-bottom: 3vh;
}

.port-section2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 5vh;
}

.port-section2 h1 {
    margin-bottom: 1vh;
}

.proof-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #262626;
    padding-block: 48px;
    padding-inline: 100px;
    border-radius: 16px;
    border: solid 2px #1068ff;
    margin-top: 3vh;
}

.proof-wrapper h2 {
    color: #fff;
    margin: 1vh;
}

.port-section2-notice {
    font-size: 0.7rem;
    margin-bottom: 1vh;
    font-weight: bold;
    color: #717171;
}

@media (max-width:768px) {
    .port-section1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-block: 4vh;
    }

    .port-section1 h1 {
        color: #fff;
        margin-bottom: 4vh;
    }

    .port-section2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-block: 4vh;
    }

    .port-section2 h1 {
        margin-bottom: 1vh;
    }

    .proof-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #262626;
        padding-block: 24px;
        padding-inline: 48px;
        border-radius: 16px;
        border: solid 2px #1068ff;
        margin-top: 3vh;
    }

    .proof-wrapper h2 {
        color: #fff;
    }

    .port-section2-notice {
        font-size: 0.8rem;
        max-width: 75%;
        margin-bottom: 2vh;
        font-weight: bold;
        color: #717171;
    }

    .proof-header {
        margin-bottom: 2vh;
    }
}