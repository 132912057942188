.copy-section1-bg{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: cover;
    z-index: -100;
}

.guide-section1{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 5vh;
}

.guide-section1 h1{
    font-size: 3rem;
    color: #fff;
}

.guide-section1 h2{
    color: #fff;
    font-size: 1.5rem;
}

.trader-name{
    font-size: 4rem;
}

.guide-section1 h3{
    font-size: 1.5rem;
    color: #fff;
    width: 60%;
}

.trader-name-span{
    color: #1068ff;
}

.guide-section1 span{
    color: #c80170;
}

.trader-name-box{
    padding-block: 16px;
    padding-inline: 32px;
    border-radius: 1.5rem;
    margin-block: 3vh;
    background-color: #fff;
    border: solid 1px #0147ff;
    box-shadow: 0 0 20px #0147ff;
}

.guide-section2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 5vh;
}

.guide-section2 button{
    text-align: center;
    min-width: 240px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 28px;
    padding-block: 12px;
    color: #fff;
    background-color: #0147ff;
    border: transparent;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 1rem;
    margin-top: 2vh;
}

.guide-section2 button:hover{
    transform: scale(1.1);
}

.guide-section2-steps{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 56px;
    background-color: #0e0e0e;
    margin-top: 5vh;
    margin-bottom: 5vh;
    border-radius: 2rem;
    padding-block: 80px;
    padding-inline: 160px;
}

.guide-section2-steps::after, .guide-section2-steps::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 2.3rem;
    padding: 0.3rem;
    background-image: conic-gradient(from var(--angle), transparent 20%, #0147ff);
    z-index: -1;
    animation: 5s spin linear infinite;
}

.guide-section2-steps::before{
    filter: blur(1.5rem);
    opacity: 1;
}

.guide-section2-steps h2{
    font-size: 2rem;
}

.guide-section2-steps h3{
    margin-top: 8px;
}

.copy-step-image{
    border: 2px solid #0147ff;
    box-shadow: 0 0 30px #0147ff;
    margin-top: 28px;
    border-radius: 2rem;
    width: 400px;
}

@media (max-width: 768px) {
    .guide-section1 h1{
        font-size: 7vw;
        color: #fff;
    }
    
    .guide-section1 h2{
        color: #fff;
        font-size: 4vw;
    }
    
    .trader-name{
        font-size: 4rem;
    }
    
    .guide-section1 h3{
        font-size: 3vw;
        color: #fff;
        width: 80%;
    }
    
    .trader-name-span{
        color: #1068ff;
    }
    
    .guide-section1 span{
        color: #c80170;
    }
    
    .trader-name-box{
        padding-block: 16px;
        padding-inline: 32px;
        border-radius: 8px;
        margin-block: 3vh;
        background-color: #fff;
        border: solid 1px #0147ff;
        box-shadow: 0 0 20px #0147ff;
    }
    
    .guide-section2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block: 5vh;
    }
    
    .guide-section2 button{
        text-align: center;
        min-width: 200px;
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 28px;
        padding-block: 12px;
        color: #fff;
        background-color: #0147ff;
        border: transparent;
        font-size: 1rem;
        font-weight: bold;
        border-radius: 1rem;
        margin-top: 2vh;
    }
    
    .guide-section2 button:hover{
        transform: scale(1.1);
    }
    
    .guide-section2-steps{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 56px;
        background-color: #0e0e0e;
        margin-top: 5vh;
        margin-bottom: 5vh;
        border-radius: 2rem;
        padding-block: 5vh;
        padding-inline: 1vw;
    }
    
    .guide-section2-steps::after, .guide-section2-steps::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        border-radius: 2.3rem;
        padding: 0.3rem;
        background-image: conic-gradient(from var(--angle), transparent 20%, #0147ff);
        z-index: -1;
        animation: 5s spin linear infinite;
    }
    
    .guide-section2-steps::before{
        filter: blur(1.5rem);
        opacity: 1;
    }
    
    .guide-section2-steps h2{
        font-size: 7vw;
    }
    
    .guide-section2-steps h3{
        margin-top: 3vw;
        width: 70%;
    }
    
    .copy-step-image{
        border: 2px solid #0147ff;
        box-shadow: 0 0 30px #0147ff;
        margin-top: 28px;
        border-radius: 8px;
        width: 70vw;
    }

    .guide-section2 h1{
        font-size: 7vw;
    }
}