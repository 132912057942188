.timer-div{
    display: flex;
    gap: 4vw;
    font-size: 3rem;
    color: #fff;
    font-weight: bold;
}

@media (max-width:768px) {
    .timer {
        font-size: 5vw;
        color: #fff;
        font-weight: bold;
    }

    .timer-div{
        display: flex;
        gap: 3vw;
    }
}

.timer-lead {
    font-size: 3rem;
    color: #fff;
    font-weight: bold;
}

@media (max-width:768px) {
    .timer-lead {
        font-size: 9vw;
        color: #fff;
        font-weight: bold;
    }
}