.vipcard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(256, 256, 256, 0.2);
    border-radius: 20px;
    box-shadow: 0px 0px 10px 4px rgba(256, 256, 256, 0.5);
    border: 2px solid #fff;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    padding-inline: 40px;
    padding-block: 28px;
}

.vipcard-container h1 {
    color: #fff;
    font-size: 3rem;
}

.vipcard-container h2 {
    color: #fff;
    font-size: 1.7rem;
}

.vipcard-container span {
    color: #ed1566;
}

.vipcard-text {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: #fff;
    padding-block: 20px;
    border-radius: 20px;
    border: transparent;
    margin-block: 32px;
}

.vipcard-text p {
    font-size: 1.7rem;
}

.paragraph {
    display: flex;
    gap: 16px;
    color: #000;
    align-items: center;
    justify-content: flex-start;
    margin-inline: 28px;
}

.vipcard-icon {
    background-color: rgb(44, 44, 44);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    padding: 8px;
    color: #fff;
}

.vipcard-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}

.vipcard-button {
    position: relative;
}

.vipcard-button::after,
.vipcard-button::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 1rem;
    padding: 0.2rem;
    background-image: conic-gradient(from var(--angle), transparent 20%, #ed1566);
    z-index: -1;
    animation: 3s spin linear infinite;
}

.vipcard-button::before {
    filter: blur(10px);
    opacity: 1;
}

@media (max-width: 768px) {
    .vipcard-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgba(256, 256, 256, 0.2);
        border-radius: 20px;
        box-shadow: 0px 0px 10px 4px rgba(256, 256, 256, 0.5);
        border: 2px solid #fff;
        backdrop-filter: blur(8px);
        -webkit-backdrop-filter: blur(8px);
        padding-inline: 32px;
        padding-block: 20px;
    }

    .vipcard-container h1 {
        color: #fff;
        font-size: 5vw;
    }

    .vipcard-container h2 {
        color: #fff;
        font-size: 4vw;
        margin-top: -1vh;
    }

    .vipcard-container span {
        color: #ed1566;
    }

    .vipcard-text {
        display: flex;
        flex-direction: column;
        gap: 8px;
        background-color: #fff;
        padding-block: 20px;
        border-radius: 20px;
        border: transparent;
        margin-block: 24px;
    }

    .vipcard-text p {
        font-size: 4vw;
    }

    .paragraph {
        display: flex;
        gap: 16px;
        color: #000;
        align-items: center;
        justify-content: flex-start;
        margin-inline: 20px;
    }

    .vipcard-icon {
        background-color: rgb(44, 44, 44);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        padding: 6px;
        color: #fff;
    }

    .vipcard-buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5vw;
    }

    .vipcard-button {
        position: relative;
    }

    .vipcard-button::after,
    .vipcard-button::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        border-radius: 10px;
        padding: 2px;
        background-image: conic-gradient(from var(--angle), transparent 20%, #ed1566);
        z-index: -1;
        animation: 3s spin linear infinite;
    }

    .vipcard-button::before {
        filter: blur(5px);
        opacity: 1;
    }
}