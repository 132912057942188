.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 1.5rem 2rem;
}

.navbar-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.navbar-logo p {
  font-size: 1.5rem;
  margin-left: 1rem;
  color: #fff;
  font-weight: bold;
}

.navbar-logo a {
  text-decoration: none;
}

.navbar-logo img {
  height: 3.5rem;
  border: 2px solid #fff;
  border-radius: 3.5rem;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 1rem;
  transition: all 0.3s ease;
}

.navbar-links.active {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1); /* 100% opacity */
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100;
  transform: translateX(100%); /* Start off-screen */
  animation: slide-in 0.3s forwards; /* Slide in from the right */
}

/* Slide-in animation */
@keyframes slide-in {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.navbar-links a {
  font-size: 1.5rem;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  display: block;
  transition: transform 0.2s;
}

.navbar-links a:hover {
  transform: scale(1.2);
}

/* Hamburger menu styles */
.menu-icon {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
}

.menu-icon div {
  width: 100%;
  height: 3px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.menu-icon.open .bar1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-icon.open .bar2 {
  opacity: 0;
}

.menu-icon.open .bar3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

/* Close button styles */
.close-menu {
  display: none;
  font-size: 2rem;
  color: #fff;
  position: fixed;
  top: 1rem;
  right: 2rem;
  cursor: pointer;
  z-index: 1110; /* Above the menu */
}

.close-menu.show {
  display: block;
}

/* Display hamburger menu on mobile */
@media (max-width: 768px) {
  .menu-icon {
    display: flex;
  }

  .navbar-links {
    display: none;
  }

  .telegram-button {
    display: none;
  }

  .navbar-links.active ~ .telegram-button {
    display: none;
  }
}

.telegram-button button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 2px solid #fff;
  font-weight: bold;
  font-size: 1rem;
  color: #fff;
  padding-inline: 2rem;
  padding-block: 0.5rem;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: transform 0.2s;
}

.telegram-button img {
  margin-left: 0.5rem;
  height: 1.2rem;
}

.telegram-button button:hover {
  transform: scale(1.1);
  box-shadow: rgba(255, 255, 255, 0.8) 0 0 10px 4px;
}
