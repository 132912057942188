.port-outter-container {
    position: relative;
    border-radius: 1.5rem;
    background-color: #fff;
    padding-inline: 20px;
    padding-block: 8px;
}

.port-outter-container::after,
.port-outter-container::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 1.7rem;
    padding: 0.2rem;
    background-image: conic-gradient(from var(--angle), transparent 20%, #0147ff);
    z-index: -1;
    animation: 3s spin linear infinite;
}

.port-outter-container::before {
    filter: blur(1.5rem);
    opacity: 1;
}

.port-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: 1001;
    background-color: rgba(49, 49, 49, 0.5);
    padding-block: 24px;
    padding-inline: 24px;
    border-radius: 8px;
    border: transparent;
}

.port-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;

}

.port-form input {
    width: 200px;
    font-size: 1rem;
    padding-block: 4px;
    padding-left: 4px;
    border-radius: 8px;
    border-color: transparent;
}

.port-blur {
    background: rgba(255, 255, 255, 0.19);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 1000;
    position: absolute;
    height: 100%;
    width: 100%;
}

.port-content {
    z-index: 999;
}

.blurred {
    filter: blur(12px);
}

.port-content {
    width: 100%;
    max-width: 800px;

}

.coin-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    background-color: #fff;
    border-radius: 8px;
}

.coin-table th {
    /*text-shadow: 0px 0px 10px;*/
    font-size: 20px;

}

.coin-table th,
.coin-table td {
    padding-block: 16px;
    padding-inline: 32px;
    color: #000;
    font-weight: bold;
    text-align: center;
}

.coin-table th {
    color: #000;
    font-weight: bold;
}

.coin-cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.coin-logo {
    width: 30px;
    height: 30px;
}

@media(max-width:768px) {
    .port-outter-container {
        position: relative;
        border-radius: 1.5rem;
        background-color: #fff;
        padding-inline: 20px;
        padding-block: 8px;
    }

    .port-outter-container::after,
    .port-outter-container::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        border-radius: 1.7rem;
        padding: 0.2rem;
        background-image: conic-gradient(from var(--angle), transparent 20%, #0147ff);
        z-index: -1;
        animation: 3s spin linear infinite;
    }

    .port-outter-container::before {
        filter: blur(1.5rem);
        opacity: 1;
    }

    .port-form-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        z-index: 1001;
        background-color: rgba(49, 49, 49, 0.5);
        padding-block: 16px;
        padding-inline: 16px;
        border-radius: 8px;
        border: transparent;
        width: 70%;
    }

    .port-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;

    }

    .port-form input {
        width: 100%;
        font-size: 1rem;
        padding-block: 12px;
        padding-left: 8px;
        border-radius: 8px;
        border-color: transparent;
        font-size: 1.3rem;
    }

    .port-blur {
        background: rgba(255, 255, 255, 0.19);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(12px);
        -webkit-backdrop-filter: blur(12px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        z-index: 1000;
        position: absolute;
        height: 100%;
        width: 100%;
    }

    .port-content {
        z-index: 999;
    }

    .blurred {
        filter: blur(12px);
    }

    .port-content {
        width: 100%;
        max-width: 800px;

    }

    .coin-table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
        background-color: #fff;
        border-radius: 8px;
    }

    .coin-table th {
        color: #000;
        font-weight: bold;
        font-size: 1.3rem;

    }

    .coin-table th,
    .coin-table td {
        padding-block: 16px;
        padding-inline: 8px;
        color: #000;
        font-weight: bold;
        text-align: center;
    }

    .coin-table td {
        font-size: 1rem;
    }

    .coin-cell {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .coin-logo {
        width: 30px;
        height: 30px;
    }
}