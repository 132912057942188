.contact-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-main-wrapper{
    position: relative;
    display: flex;
    width: 100vw;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
    margin-bottom: 10vh;
}

.form-header{
    position: absolute;
    top: 0%;
    translate: 0% -50%;
    padding-block: 16px;
    padding-inline: 28px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 16px;
    box-shadow: 0 0 30px rgba(256, 256, 256, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border: 2px solid rgba(255, 255, 255, 0.3);
    z-index: 10;
}

.form-header h1{
    font-size: 3rem;
    color: #fff;
}

.form-header p{
    font-size: 1.5rem;
    color: #fff;
}

.contact-form{
    position: relative;
    border-radius: 1.5rem;
    padding-inline: 112px;
    padding-bottom: 56px;
    background-color: #0e0e0e;
}

.contact-form::after, .contact-form::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 1.7rem;
    padding: 0.2rem;
    background-image: conic-gradient(from var(--angle), transparent 20%, #1068ff);
    z-index: -1;
    animation: 3s spin linear infinite;
}

.contact-form::before{
    filter: blur(1.5rem);
    opacity: 1;
}

.contact-field {
    margin-bottom: 20px;
    width: 100%;
}

.contact-field label {
    font-size: 1.2rem;
    color: #fff;
    margin-bottom: 8px;
    display: block;
}

.contact-field input,
.contact-field textarea {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border-radius: 8px;
    border: 1px solid #ddd;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
    background: rgba(255, 255, 255, 0.8);
    color: #333;
    transition: box-shadow 0.3s ease;
}

.contact-field input:focus,
.contact-field textarea:focus {
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.2);
    outline: none;
}

.contact-field textarea {
    resize: vertical;
    min-height: 120px;
}




















.contact-form button{
    width: 15rem;
    margin-top: 2vh;
    height: 3rem;
    padding-inline: 2rem;
    padding-block: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
    color: #fff;
    background-color: #1068ff;
    border: transparent;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s;
}

.contact-form button:hover{
    transform: scale(1.1);
}

@media (max-width:768px) {
    .contact-main{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .contact-main-wrapper{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10vh;
        margin-bottom: 10vh;
    
    }
    
    .form-header{
        position: absolute;
        top: 0%;
        translate: 0% -50%;
        padding-block: 16px;
        padding-inline: 28px;
        background: rgba(255, 255, 255, 0.05);
        border-radius: 16px;
        box-shadow: 0 0 30px rgba(256, 256, 256, 0.1);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        border: 2px solid rgba(255, 255, 255, 0.3);
        z-index: 10;
    }
    
    .form-header h1{
        font-size: 7vw;
        color: #fff;
    }
    
    .form-header p{
        font-size: 1.5rem;
        color: #fff;
    }
    
    .contact-form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        border-radius: 1.5rem;
        padding-inline: 3vw;
        padding-bottom: 56px;
        background-color: #0e0e0e;
    }
    
    .contact-form::after, .contact-form::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        border-radius: 1.7rem;
        padding: 0.2rem;
        background-image: conic-gradient(from var(--angle), transparent 20%, #1068ff);
        z-index: -1;
        animation: 3s spin linear infinite;
    }
    
    .contact-form::before{
        filter: blur(1.5rem);
        opacity: 1;
    }
    
    .contact-field {
        margin-bottom: 20px;
        width: 60vw;
    }
    
    .contact-field label {
        font-size: 4vw;
        color: #fff;
        margin-bottom: 8px;
    }
    
    .contact-field input,
    .contact-field textarea {
        width: 90%;
        padding: 5px;
        font-size: 4vw;
        border-radius: 8px;
        border: 1px solid #ddd;
        box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
        background: rgba(255, 255, 255, 0.8);
        color: #333;
        transition: box-shadow 0.3s ease;
    }
    
    .contact-field input:focus,
    .contact-field textarea:focus {
        box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.2);
        outline: none;
    }
    
    .contact-field textarea {
        resize: vertical;
        min-height: 20px;
    }
    
    .contact-form button{
        width: 40vw;
        margin-top: 2vh;
        height: 3rem;
        padding-inline: 2rem;
        padding-block: 0.5rem;
        font-weight: bold;
        font-size: 1rem;
        color: #fff;
        background-color: #1068ff;
        border: transparent;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: transform 0.2s;
    }
    
    .contact-form button:hover{
        transform: scale(1.1);
    }
    
}
