
.blofin-aff-section1{
    padding-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blofin-aff-section1-header-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blofin-aff-section1 h1{
    font-size: 3vw;
    color: #fff;
}

.blofin-aff-section1 h2{
    font-size: 2vw;
    max-width: 80%;
    color: #fff;
}

.blofin-aff-section1 span{
    color: #ff8400;
}

.blofin-aff-section1-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3vh;
}

.aff-images{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vh;
}

.aff-images img{
    border-radius: 500px;
    border: #fff solid 6px;
    width: auto;
    height: 25vh;
}

.aff-images div{
    border-radius: 500px;
    width: 10px;
    height: 18vh;
    background-color: #fff;
}

.aff-names{
    border-radius: 1rem;
    padding-inline: 3vw;
    padding-block: 1vh;
    background-color: #fff;
    margin-top: 3vh;
}

.aff-names h1{
    color: #000;
}

.blofin-aff-section1-buttons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4vw;
    margin-block: 2vh;
}

.blofin-button-light{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 64px;
    padding-block: 16px;
    font-size: 1rem;
    font-weight: bold;
    color: #ff8400;
    background-color: #000;
    border: #ff8400 solid 4px;
    border-radius: 0.8rem;
    cursor: pointer;
    transition: transform 0.2s;
}

.blofin-button-light:hover{
    transform: scale(1.1);
    box-shadow: rgba(255, 132, 0, 0.8) 0 0 20px 5px;
    border: solid 2px #ff8400;
}

.blofin-aff-section1-hrline{
    width: 30vw;
    border: solid 4px #fff;
    margin-block: 2vh;
}

.blofin-copy-section1-images-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4vw;
    margin-top: 2vh;
    margin-bottom: 5vh;
}

.blofin-copy-section1-images-main h2{
    font-size: 1.4vw;
    margin-top: 0;
}

.blofin-copy-section1-images-left{
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 width: 50vw;
}

.blofin-copy-section1-images-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
}

.blofin-copy-section1-images-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blofin-copy-section1-images-wrapper img{
    border: 4px solid #ff8400;
    box-shadow: 0 0 25px #ff8400;
    border-radius: 1rem;
    width: 30vw;
    margin-top: 3vh;
}

/*SECTION 2*/

.blofin-aff-section2{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 5vh;
}

.blofin-aff-section2 span{
    color: #ff8400;
}

.blofin-aff-section2 h1{
    font-size: 3rem;
}

.blofin-aff-section2 h2{
    font-size: 2rem;
}

.blofin-aff-section2-steps{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 56px;
    background-color: #111111;
    margin-block: 5vh;
    border-radius: 2rem;
    padding-block: 80px;
    padding-inline: 160px;
}

.blofin-aff-section2-steps::after, .blofin-aff-section2-steps::before{
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 2.3rem;
    padding: 0.3rem;
    background-image: conic-gradient(from var(--angle), transparent 20%, #ff8400);
    z-index: -1;
    animation: 5s spin linear infinite;
}

.blofin-aff-section2-steps::before{
    filter: blur(1.5rem);
    opacity: 1;
}

.blofin-button-dark{
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 28px;
    padding-block: 12px;
    color: #000;
    background-color: #ff8400;
    border: transparent;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 1rem;
    margin-top: 2vh;
}

.blofin-button-dark-steps{
    text-align: center;
    min-width: 240px;
    min-height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-inline: 28px;
    padding-block: 12px;
    color: #000;
    background-color: #ff8400;
    border: transparent;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 1rem;
    margin-top: 2vh;
}

.blofin-button-dark-steps:hover{
    transform: scale(1.1);
}

.blofin-button-dark:hover{
    transform: scale(1.1);
}

.blofin-aff-step{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blofin-aff-step h2{
    font-size: 3rem;
    color: #fff;
}

.blofin-aff-step h3{
    font-size: 2rem;
    color: #fff;
}

.blofin-aff-step p{
    font-size: 1.5rem;
    color: #fff;
}

.blofin-aff-step button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blofin-aff-step button:hover{
    transform: scale(1.1);
}

.blofin-aff-step-images{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.blofin-aff-step-images img{
    margin-top: 2vh;
    border-radius: 0.7rem;
    border: 2px solid #fff;
    width: 150px;
    transition: transform 0.2s;
}

.blofin-aff-step-images img:hover{
    transform: scale(1.1);
}

.blofin-aff-section3{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-block: 5vh;
    background-color: #0e0e0e;
}

.blofin-aff-section3 h1{
    color: #fff;
    font-size: 3rem;
}

.blofin-aff-section3 h2{
    color: #fff;
    font-size: 2rem;
}

.blofin-aff-section3-row{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:56px;
    margin-block: 5vh;
    width: 100vw;
}

.blofin-aff-section3-row img{
    border: 2px solid #ff8400;
    box-shadow: 0 0 15px #ff8400;
    border-radius: 1rem;
    margin-top: 3vh;
    width: 400px;
}

.blofin-aff-section3-left{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.blofin-aff-section3-right{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
}

.blofin-aff-section3-right-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blofin-aff-section3-left-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blofin-aff-section3-pnl-main{
    margin-top: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    gap: 28px;
}

.blofin-aff-section3-pnl-row{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 856px;
}

.blofin-aff-section3-pnl-row-images{
    width: 856px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.blofin-aff-section3-pnl-row-images img{
    border: 2px solid #ff8400;
    box-shadow: 0 0 15px #ff8400;
    border-radius: 1rem;
    width: calc(856px/3 - 28px);
}






@media (max-width:768px) {
    .blofin-aff-section1{
        padding-top: 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .blofin-aff-section1 h1{
        font-size: 5vw;
        color: #fff;
    }

    .blofin-aff-section1 h2{
        font-size: 4vw;
        max-width: 80%;
        color: #fff;
    }
    
    .blofin-aff-section1 span{
        color: #ff8400;
    }
    
    .blofin-aff-section1-header{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
    }

    .blofin-aff-section1-header-text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 4vh;
        gap: 1vh;
    }
    
    .aff-images{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 3vh;
    }
    
    .aff-images img{
        border-radius: 500px;
        border: #fff solid 2px;
        width: auto;
        height: 17vh;
    }
    
    .aff-images div{
        border-radius: 500px;
        width: 4px;
        height: 10vh;
        background-color: #fff;
    }
    
    .aff-names{
        border-radius: 1rem;
        padding-inline: 3vw;
        padding-block: 1vh;
        background-color: #fff;
        margin-top: 2vh;
    }
    
    .aff-names h1{
        color: #000;
    }

    .blofin-aff-section1-buttons{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 5vw;
    }

    .blofin-button-light{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 6vw;
        padding-block: 2vw;
        font-size: 4vw;
        font-weight: bold;
        color: #ff8400;
        background-color: #000;
        border-radius: 8px;
        cursor: pointer;
        border: solid 1px #ff8400;
    }

    .blofin-aff-section1-hrline{
        width: 70vw;
        border: solid 2px #fff;
        margin-block: 2vh;
    }
    
    .blofin-copy-section1-images-main{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5vh;
        margin-top: 2vh;
        margin-bottom: 5vh;
    }
    
    .blofin-copy-section1-images-main h2{
        font-size: 5vw;
        margin-top: 0;
    }
    
    .blofin-copy-section1-images-left{
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     width: 100vw;
    }
    
    .blofin-copy-section1-images-right{
        order: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
    }
    
    .blofin-copy-section1-images-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .blofin-copy-section1-images-wrapper img{
        border: 2px solid #ff8400;
        box-shadow: 0 0 20px #ff8400;
        border-radius: 1rem;
        width: 70vw;
        margin-top: 2vh;
    }

    .blofin-aff-section2{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block: 5vh;
    }
    
    .blofin-aff-section2 span{
        color: #ff8400;
    }
    
    .blofin-aff-section2 h1{
        font-size: 7vw;
    }
    
    .blofin-aff-section2 h2{
        font-size: 4vw;
        max-width: 70vw;
    }

    .blofin-button-dark{
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 28px;
        padding-block: 12px;
        color: #000;
        background-color: #ff8400;
        border: transparent;
        font-size: 4vw;
        font-weight: bold;
        border-radius: 1rem;
        margin-top: 2vh;
    }

    .blofin-button-dark-steps{
        text-align: center;
        min-width: 200px;
        min-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 28px;
        padding-block: 12px;
        color: #000;
        background-color: #ff8400;
        border: transparent;
        font-size: 4vw;
        font-weight: bold;
        border-radius: 1rem;
        margin-top: 2vh;
    }

    .blofin-aff-section2-steps{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 56px;
        background-color: #111111;
        margin-block: 5vh;
        border-radius: 2rem;
        padding-block: 5vh;
        padding-inline: 5vw;
    }
    
    .blofin-aff-section2-steps::after, .blofin-aff-section2-steps::before{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 50%;
        left: 50%;
        translate: -50% -50%;
        border-radius: 2.3rem;
        padding: 0.3rem;
        background-image: conic-gradient(from var(--angle), transparent 20%, #ff8400);
        z-index: -1;
        animation: 5s spin linear infinite;
    }
    
    .blofin-aff-section2-steps::before{
        filter: blur(1.5rem);
        opacity: 1;
    }
    
    .blofin-aff-step{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .blofin-aff-step h2{
        font-size: 7vw;
        color: #fff;
    }
    
    .blofin-aff-step h3{
        font-size: 5vw;
        color: #fff;
    }
    
    .blofin-aff-step p{
        font-size: 4vw;
        color: #fff;
    }
    
    .blofin-aff-step button{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .blofin-aff-step-images{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
    }
    
    .blofin-aff-step-images img{
        margin-top: 2vh;
        border-radius: 8px;
        border: 2px solid #fff;
        width: 25vw;
        transition: transform 0.2s;
    }

    .blofin-aff-section2-footer{
        max-width: 80%;
        margin-bottom: 1vh;
    }

    .blofin-aff-section3{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block: 5vh;
        background-color: #0e0e0e;
    }
    
    .blofin-aff-section3 h1{
        color: #fff;
        font-size: 7vw;
    }
    
    .blofin-aff-section3 h2{
        color: #fff;
        font-size: 4vw;
    }
    
    .blofin-aff-section3-row{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap:3vh;
        margin-block: 3vh;
        width: 100vw;
    }
    
    .blofin-aff-section3-row img{
        border: 1.5px solid #ff8400;
        box-shadow: 0 0 5px #ff8400;
        border-radius: 1rem;
        margin-top: 2vh;
        width: 80vw;
    }
    
    .blofin-aff-section3-left{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
    
    .blofin-aff-section3-right{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
    }
    
    .blofin-aff-section3-right-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    
    .blofin-aff-section3-left-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .blofin-aff-section3-pnl-main{
        margin-top: 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100vw;
        gap: 5vw;
    }
    
    .blofin-aff-section3-pnl-row{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    
    .blofin-aff-section3-pnl-row-images{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
    }
    
    .blofin-aff-section3-pnl-row-images img{
        border: 1.5px solid #ff8400;
        box-shadow: 0 0 5px #ff8400;
        border-radius: 8px;
        width: 25vw;
    }
}
