.footer-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
    padding-top: 24px;
    padding-bottom: 12px;
}

.footer-links{
    font-size: 1rem;
    color: #fff;
}

.footer-main img{
    width: 36px;
    margin-right: 16px;
}

.footer-horizontal{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer-left{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    margin-right: 28px;
    width: 50vw;
}

.footer-left-text{
    text-align: left;
}

.footer-middle{
    border: 2px solid #fff;
    height: 52px;
}

.footer-right{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 28px;
    width: 50vw;
}

.footer-copyright{
    font-size: 0.8rem;
    color: lightgray;
    margin-top: 8px;
}

.footer-studio{
    font-size: 0.8rem;
    color: lightgray;
    margin-top: 12px;
}

@media (max-width:768px) {
    .footer-main{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: black;
        padding-top: 2vh;
        padding-bottom: 1vh;
    }
    
    .footer-links{
        font-size: 3vw;
        color: #fff;
    }
    
    .footer-main img{
        width: 7vw;
        margin-right: 3vw;
    }
    
    .footer-horizontal{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    
    .footer-left{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        margin-right: 5vw;
        width: 50vw;
    }
    
    .footer-left-text{
        text-align: left;
    }
    
    .footer-middle{
        border: 1px solid #fff;
        height: 5vh;
    }
    
    .footer-right{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        margin-left: 5vw;
        width: 50vw;
    }
    
    .footer-copyright{
        font-size: 2vw;
        color: lightgray;
        margin-top: 1vh;
    }
    
    .footer-studio{
        font-size: 2vw;
        color: lightgray;
        margin-top: 1vh;
    }
}
