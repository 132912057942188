.risk-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    background-color: #0e0e0e;
    z-index: -500;
}

.risk-main{
    background-color: #0e0e0e;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: justify;
}

.risk-main p{
    margin-top: 5vh;
    width: 60%;
}