.results-section1{
    padding-top: 5vh;
    min-height: 100%;
    padding-bottom: 5vh;
}

.results-section1 h1{
    font-size: 4vw;
    color: #fff;
    margin-bottom: 3vh;
}

.results-pdfs{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    margin-bottom: 2vh;
}

.result{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.result img{
    width: 25vw;
    height: auto;
    border-radius: 1vw;
    border: solid 2px #fff;
    margin-top: 2vh;
    margin-bottom: 1vh;
}

.result h2{
    font-size: 2vw;
    color: #fff;
}

.result h3{
    margin-bottom: 3vh;
    color: #fff;
    font-size: 1vw;
}

.result span{
    color: #0bf511;
    text-shadow: 0px 0px 15px #0bf511;
}

@media (max-width: 768px) {

    .results-section1 h1{
        font-size: 8vw;
        color: #fff;
        margin-bottom: 3vh;
    }

    .results-pdfs {
        flex-direction: column;
        gap: 4vh;
    }

    .result:nth-child(1) {
        order: 3;
    }

    .result:nth-child(2) {
        order: 2;
    }

    .result:nth-child(3) {
        order: 1;
    }

    .result img {
        width: 80vw; /* Adjust image width for better mobile fit */
        border-radius: 4vw;
        margin-bottom: 1vh;
    }

    .result h2 {
        font-size: 6vw; /* Increase font size for better readability on mobile */
    }

    .result h3{
        margin-bottom: 2vh;
        color: #fff;
        font-size: 4vw;
    }
    
    .result span{
        color: #0bf511;
        text-shadow: 0px 0px 15px #0bf511;
    }
}
