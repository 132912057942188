.leaderboard-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5vh;
}

.leaderboard-section1 h1 {
    font-size: 4vw;
    color: #fff;
}

.leaderboard-section1-header {
    margin-bottom: 3vh;
}

.leaderboard-section1 span {
    font-size: 6vw;
    color: #0bf511;
    text-shadow: 0px 0px 15px #0bf511;
}

.leaderboard-top3 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5vw;
    margin-top: 5vh;
}

.leaderboard-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2rem;
    transition: scale 0.3s;
    min-width: 10vw;
    padding: 2vh 3vw;
}

.leaderboard-card:hover {
    scale: 1.1;
}

.card-uid {
    width: 100%;
    margin-top: 2vh;
    color: #fff;
}

.card-volume {
    width: 100%;
    margin-top: 1vh;
    color: #fff;
}

.leaderboard-card h1 {
    font-size: 3vw;
}

.leaderboard-card h2 {
    font-size: 2vw;
}

.leaderboard-card p {
    font-size: 1.5vw;
}

.first {
    color: #fff;
    order: 1;
    border: 3px solid #FFD700;
    box-shadow: 0 0 30px 1px #FFD700;
    margin-bottom: 8vh;
}

.first h1,
.first h2 {
    color: #FFD700;
    text-shadow: 0px 0px 15px #FFD700;
}

.second {
    order: 0;
    color: #fff;
    border: 3px solid #afafaf;
    box-shadow: 0 0 30px 1px #afafaf;
}

.second h1,
.second h2 {
    color: #afafaf;
    text-shadow: 0px 0px 15px #afafaf;
}

.third {
    order: 2;
    color: #fff;
    border: 3px solid #CD7F32;
    box-shadow: 0 0 30px 1px #CD7F32;
}

.third h1,
.third h2 {
    color: #CD7F32;
    text-shadow: 0px 0px 15px #CD7F32;
}

.leaderboard-section1-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3vw;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

/*SECTION 2*/

.leaderboard-section2 {
    background-image: url(../../../public/assets/images/backgrounds/section2bg.svg);
    background-size: cover;
}

.leaderboard-section2-fade {
    padding-block: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgb(255, 0, 228);
    background: linear-gradient(0deg, rgba(255, 0, 228, 0) 0%, rgba(255, 255, 255, 1) 95%);
}

.leaderboard-section2-header {
    font-size: 4vw;
}

.leaderboard-section2-condition {
    font-size: 2vw;
    font-weight: 500;
    margin-bottom: 3vh;
}

.leaderboard-section2-calculation {
    margin-top: 2vh;
}

.leaderboard-section2-calculation p {
    font-size: 1.5vw;
    color: #4d4d4d;
}

/*style={{marginTop:'2vh', fontWeight:'bold', fontSize:'2vw'}}
style={{fontSize:'1.5vw'}}*/

.leaderboard-section2-fade span {
    color: red;
}

.leaderboard-table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: rgba(0, 0, 0, 0.8);
    /* Set background */
    border-radius: 15px;
    /* Set border radius */
    overflow: hidden;
    /* Apply rounding effect to borders */
    border: 2px solid #000;
    margin-top: 1vh;
}

.leaderboard-section2-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 3vh;
}

.leaderboard-table table {
    width: 100%;
    border-spacing: 0;
}

.leaderboard-table th,
.leaderboard-table td {
    text-align: center;
    /* Align content to center */
    padding-block: 1vh;
    /* Vertical padding */
    padding-inline: 2vw;
    /* Horizontal padding */
    border: 1px solid #000;
    /* White borders between cells */
}

.leaderboard-table th {
    background-color: rgba(255, 255, 255, 0.1);
    /* Slightly transparent header background */
    color: white;
    /* Text color */
    font-weight: bold;
    font-size: 2vw;
}

.leaderboard-table td {
    font-size: 1.5vw;
    color: #fff;
    /* White text color for cells */
}

.leaderboard-table tr:last-child td {
    border-bottom: 0;
    /* Remove border from the last row's bottom */
}

.leaderboard-table tbody {
    max-width: 500px;
}

/*SECTION 3*/

.leaderboard-section3 {
    background-image: url(../../../public/assets/images/backgrounds/bg2.png);
    background-size: cover;
    padding-block: 5vh;
}

.leaderboard-section3 h1 {
    font-size: 4vw;
    color: #fff;
}

.prizes-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-block: 5vh;
}

.prizes-table {
    width: 100%;
    max-width: 50vw;
    margin: 0 auto;
}

.prizes-table table {
    width: 100%;
    border-collapse: separate;
    /* Use 'separate' to allow border-radius */
    border-spacing: 0;
    /* Removes gaps between cells */
    background-color: rgba(0, 0, 0, 0.8);
    table-layout: fixed;
    border-radius: 12px;
    /* Apply border-radius to the outer edges of the table */
    border: 2px solid #fff;
    box-shadow: 0 0 15px 2px #fff;
    overflow: hidden;
    /* Ensures content does not overflow beyond rounded corners */
}

.prizes-table th,
.prizes-table td {
    border: 1px solid #fff;
    padding: 12px;
    text-align: center;
    font-size: 3vw;
    color: #fff;
    width: 50%;
    /* Equal width for both columns */
}

.prizes-table th {
    background-color: #484848;
    font-weight: bold;
}

/* Remove border-radius from individual cells */
.prizes-table th,
.prizes-table td {
    border-radius: 0;
}

.uid-tutorial {
    margin-top: 5vh;
}

.uid-tutorial video {
    margin-top: 3vh;
    height: 60vh;
    border-radius: 2vw;
}

.uid-lookup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.uid-lookup h2 {
    font-size: 2vw;
    font-weight: bold;
    margin-bottom: 1vh;
}

.uid-lookup-input {
    background-color: #333333;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vw;
    padding: 24px;
    border-radius: 1.6rem;
}

.uid-lookup-input-top {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}

.uid-lookup-input input {
    border-radius: 0.8rem;
    border: transparent;
    color: #000;
    font-size: 1rem;
    padding-left: 1vw;
}

.uid-lookup-input input::placeholder {
    color: #000;
    font-size: 1rem;
}

.uid-lookup-input-bottom {
    margin-top: 2vh;
    width: 100%;
}

.user-position-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 5vw;
}

.user-position-info-left {
    text-align: left;
}

.user-position-info-right {
    text-align: left;
}

.user-position-info {
    font-size: 1.5vw;
    color: #000;
}

.last-update {
    font-size: 2vw;
    font-weight: bold;
}

/* Mobile Styles */
@media only screen and (max-width: 768px) {
    .leaderboard-section1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-block: 5vh;
    }

    .leaderboard-section1 h1 {
        font-size: 8vw;
        color: #fff;
    }

    .leaderboard-section1 span {
        font-size: 12vw;
        color: #0bf511;
        text-shadow: 0px 0px 15px #0bf511;
    }

    .leaderboard-top3 {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8vw;
    }

    .leaderboard-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 2vw;
        transition: scale 0.3s;
        min-width: 15vw;
        padding: 2vh 3vw;
    }

    .leaderboard-card:hover {
        scale: 1.1;
    }

    .card-uid {
        width: 100%;
        margin-top: 2vh;
        color: #fff;
    }

    .card-volume {
        width: 100%;
        margin-top: 1vh;
        color: #fff;
    }

    .leaderboard-card h1 {
        font-size: 5vw;
    }

    .leaderboard-card h2 {
        font-size: 4vw;
    }

    .leaderboard-card p {
        font-size: 3vw;
    }

    .first {
        color: #fff;
        order: 1;
        border: 3px solid #FFD700;
        box-shadow: 0 0 15px 1px #FFD700;
        margin-bottom: 8vh;
    }

    .first h1,
    .first h2 {
        color: #FFD700;
        text-shadow: 0px 0px 10px #FFD700;
    }

    .second {
        order: 0;
        color: #fff;
        border: 3px solid #afafaf;
        box-shadow: 0 0 15px 1px #afafaf;
    }

    .second h1,
    .second h2 {
        color: #afafaf;
        text-shadow: 0px 0px 10px #afafaf;
    }

    .third {
        order: 2;
        color: #fff;
        border: 3px solid #CD7F32;
        box-shadow: 0 0 15px 1px #CD7F32;
    }

    .third h1,
    .third h2 {
        color: #CD7F32;
        text-shadow: 0px 0px 10px #CD7F32;
    }

    .leaderboard-section1-buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5vw;
        margin-top: 3vh;
        margin-bottom: 3vh;
    }

    /*SECTION 2*/

    .leaderboard-section2 {
        background-image: url(../../../public/assets/images/backgrounds/section2bg.svg);
        background-size: cover;
    }

    .leaderboard-section2-fade {
        padding-block: 5vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: rgb(255, 0, 228);
        background: linear-gradient(0deg, rgba(255, 0, 228, 0) 0%, rgba(255, 255, 255, 1) 95%);
    }

    .leaderboard-section2-header {
        font-size: 8vw;
    }

    .leaderboard-section2-condition {
        font-size: 4vw;
        max-width: 80%;
        font-weight: 500;
        margin-bottom: 3vh;
    }

    .leaderboard-section2-fade span {
        color: red;
    }

    .leaderboard-section2-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 3vh;
    }

    .leaderboard-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: 0 auto;
        border-collapse: collapse;
        background-color: rgba(0, 0, 0, 0.8);
        /* Set background */
        border-radius: 10px;
        /* Set border radius */
        overflow: hidden;
        /* Apply rounding effect to borders */
        border: 1px solid #000;
        margin-top: 1vh;
    }

    .leaderboard-table table {
        width: 100%;
        border-spacing: 0;
    }

    .leaderboard-table th,
    .leaderboard-table td {
        text-align: center;
        /* Align content to center */
        padding-block: 1vh;
        /* Vertical padding */
        padding-inline: 2vw;
        /* Horizontal padding */
        border: 1px solid #000;
        /* White borders between cells */
    }

    .leaderboard-table th {
        background-color: rgba(255, 255, 255, 0.1);
        /* Slightly transparent header background */
        color: white;
        /* Text color */
        font-weight: bold;
        font-size: 3vw;
    }

    .leaderboard-table td {
        font-size: 2vw;
        color: #fff;
        /* White text color for cells */
    }

    .leaderboard-table tr:last-child td {
        border-bottom: 0;
        /* Remove border from the last row's bottom */
    }

    .leaderboard-table tbody {
        max-width: 95vw;
    }

    /*SECTION 3*/

    .leaderboard-section3 {
        background-image: url(../../../public/assets/images/backgrounds/bg2.png);
        background-size: cover;
        padding-block: 5vh;
    }

    .leaderboard-section3 h1 {
        font-size: 8vw;
        color: #fff;
    }

    .prizes-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 5vh;
    }

    .prizes-table {
        width: 100%;
        max-width: 80vw;
        margin: 0 auto;
    }

    .prizes-table table {
        width: 100%;
        border-collapse: separate;
        /* Use 'separate' to allow border-radius */
        border-spacing: 0;
        /* Removes gaps between cells */
        background-color: rgba(0, 0, 0, 0.8);
        table-layout: fixed;
        border-radius: 12px;
        /* Apply border-radius to the outer edges of the table */
        border: 2px solid #fff;
        box-shadow: 0 0 15px 2px #fff;
        overflow: hidden;
        /* Ensures content does not overflow beyond rounded corners */
    }

    .prizes-table th,
    .prizes-table td {
        border: 1px solid #fff;
        padding: 12px;
        text-align: center;
        font-size: 3vw;
        font-weight: bold;
        color: #fff;
        width: 50%;
        /* Equal width for both columns */
    }

    .prizes-table th {
        background-color: #484848;
        font-weight: bold;
    }

    /* Remove border-radius from individual cells */
    .prizes-table th,
    .prizes-table td {
        border-radius: 0;
    }

    .uid-tutorial {
        margin-top: 5vh;
    }

    .uid-tutorial video {
        margin-top: 3vh;
        height: 60vh;
        border-radius: 2vw;
    }

    .prizes-table th,
    .prizes-table td {
        padding: 10px;
        font-size: 0.9rem;
    }

    .prizes-table table {
        table-layout: auto;
    }

    .leaderboard-section2-calculation {
        margin-top: 2vh;
    }

    .leaderboard-section2-calculation p {
        font-size: 3vw;
        color: #4d4d4d;
    }

    .uid-lookup {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .uid-lookup h2 {
        font-size: 4vw;
        font-weight: bold;
        margin-bottom: 1vh;
    }

    .uid-lookup-input {
        background-color: #333333;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2vw;
        padding: 12px;
        border-radius: 1.2rem;
    }

    .uid-lookup-input-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 6vw;
    }

    .uid-lookup-input input {
        border-radius: 8px;
        border: transparent;
        color: #000;
        font-size: 1rem;
        padding-left: 3vw;
    }

    .uid-lookup-input input::placeholder {
        color: #000;
        font-size: 1rem;
    }

    .uid-lookup-input-bottom {
        margin-top: 1vh;
        width: 100%;
    }

    .user-position-info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 0;
        padding-left: 5%;
    }

    .user-position-info-left {
        width: 100%;
        order: 0;
        text-align: left;
    }

    .user-position-info-right {
        width: 100%;
        order: 1;
        text-align: left;
    }

    .user-position-info {
        font-size: 1rem;
        color: #000;
    }

    .last-update {
        font-size: 1rem;
        font-weight: bold;
    }
}